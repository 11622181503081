const COMMON_URL = `${process.env.REACT_APP_RICE_STOCKPILE_URL}`

const RiceStockApiUrls = {
  IMPORTER_PROFILE_UPLOAD: `${COMMON_URL}/azureblob/importer-profile/upload`,
  RICE_PO_UPLOAD: `${COMMON_URL}/azureblob/rice-po/upload`,
  STOCKPILE_DATA_UPLOAD: `${COMMON_URL}/azureblob/stockpile-data/upload`,
  GET_POWERBI_EMBEDING_INFO: `${COMMON_URL}/powerbi-embeding/get`,
  RICE_STOCKPILE_CONFIG_UPLOAD: `${COMMON_URL}/config/upload`,
  IMPORTER_GET: `${COMMON_URL}/importer/get`,
  IMPORTER_POST: `${COMMON_URL}/importer/create`,
  IMPORTER_PUT: `${COMMON_URL}/importer/update`,
  IMPORTER_DELETE: `${COMMON_URL}/importer/delete`,
  IMPORTER_EXPORT: `${COMMON_URL}/importer/download`
}

export default RiceStockApiUrls
