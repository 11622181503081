const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const CONNECTOR_MAPPINGS = `${CEC_URL}/connector-mappings`

const GET = `get`
const CREATE = `create`
const UPDATE = `update`
const DELETE = `delete`

const ConnectorMappingApiUrls = {
  CONNECTOR_MAPPINGS :CONNECTOR_MAPPINGS,
  GET: `${CONNECTOR_MAPPINGS}/${GET}`,
  CREATE: `${CONNECTOR_MAPPINGS}/${CREATE}`,
  UPDATE: `${CONNECTOR_MAPPINGS}/${UPDATE}`,
  DELETE: `${CONNECTOR_MAPPINGS}/${DELETE}`
}

export default ConnectorMappingApiUrls