const COMMON_URL = `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/custom-user-account`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const CustomUserAccountApiUrls = {
  GET_USER: `${USER_RESOURCE_URL}/user/get`,
  GET_USER_DETAIL: `${USER_RESOURCE_URL}/user/detail/get`,
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
};

export default CustomUserAccountApiUrls