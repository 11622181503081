const COMMON_URL = `${process.env.REACT_APP_FB_ORIGIN_URL}/booking-sync-log`

const BookingSyncLogApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
};

export default BookingSyncLogApiUrls
