const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const CecPostcodeConfigApiUrls = {

    //UOM Conversion
    GET_POSTCODE_CONFIG: `${SCO_URL}/cec/postcodeConfig/get`,
    CREATE_POSTCODE_CONFIG: `${SCO_URL}/cec/postcodeConfig/create`,
    EDIT_POSTCODE_CONFIG: `${SCO_URL}/cec/postcodeConfig/update`,
    List_POSTCODE_CONFIG: `${SCO_URL}/cec/postcodeConfig/get`,
    DELETE_POSTCODE_CONFIG: `${SCO_URL}/cec/postcodeConfig/delete`,
}

export default CecPostcodeConfigApiUrls