const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const CompanyUserGroupApiUrls = {
  GET: `${COMMON_URL}/company-for-user-group/company-user-group/get`,
  POST: `${COMMON_URL}/company-for-user-group/company-user-group/create`,
  PUT: `${COMMON_URL}/company-for-user-group/company-user-group/update`,
  DELETE: `${COMMON_URL}/company-for-user-group/company-user-group/delete`,
  EXPORT: `${COMMON_URL}/company-for-user-group/company-user-group/download`
}

export default CompanyUserGroupApiUrls
