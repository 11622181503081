import EndPoints from 'src/constants/CommonEndPoints'

const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const CONNECTOR_APPS = `${CEC_URL}/connector-apps`

const ConnectorAppsApiUrls = {
  CONNECTOR_APPS: CONNECTOR_APPS,
  GET: `${CONNECTOR_APPS}/${EndPoints.GET}`,
  CREATE: `${CONNECTOR_APPS}/${EndPoints.CREATE}`,
  UPDATE: `${CONNECTOR_APPS}/${EndPoints.UPDATE}`,
  DELETE: `${CONNECTOR_APPS}/${EndPoints.DELETE}`

}

export default ConnectorAppsApiUrls
