// import ApiUrlConfiguration from 'src/ApiUrlConfiguration'

// const PROTOCOL = ApiUrlConfiguration.PROTOCOL
// const HOST = ApiUrlConfiguration.HOST
// const PORT = ApiUrlConfiguration.port.TCAL_VS_VOYAGE
const COMMON_URL = `${process.env.REACT_APP_VOYAGE_ORIGIN_URL}/calista-logistic-ng-app-main/vs`

const VoyageLegApiUrls = {
  GET: `${COMMON_URL}/tcal-vs-voyage/voyage-leg/get`,
  POST: `${COMMON_URL}/tcal-vs-voyage/voyage-leg/create`,
  PUT: `${COMMON_URL}/tcal-vs-voyage/voyage-leg/update`,
  DELETE: `${COMMON_URL}/tcal-vs-voyage/voyage-leg/delete`,
  EXPORT: `${COMMON_URL}/tcal-vs-voyage/voyage-leg/download`
}

export default VoyageLegApiUrls
