const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const CecAccountApiUrls = {
    GET_ACCOUNT: `${SCO_URL}/company/get`,
    CREATE_ACCOUNT: `${SCO_URL}/company/account/hdr/createAccount`,
    EDIT_ACCOUNT: `${SCO_URL}/company/account/hdr/editAccount`,
    DELETE_ACCOUNT: `${SCO_URL}/company/account/hdr/deleteAccount`,

    GET_ACCOUNT_GET: `${SCO_URL}/company/account`,
    GET_ACCOUNT_CUSTOMER: `${SCO_URL}/company/account/hdr/getJobCustomer`,
    GET_ACCOUNT_ISSUER: `${SCO_URL}/company/account/hdr/getJobIssuer`,
    GET_ACCOUNT_BY_PARTYID: `${SCO_URL}/company/account/getByPartyId`,

}

export default CecAccountApiUrls