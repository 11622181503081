const CALISTA_ADVISORY_COUNTRY_URL = `${process.env.REACT_APP_CIA_ORIGIN_URL}/cia-admin/country`

const CalistaAdvisoryCountryApiUrls = {
    GET: `${CALISTA_ADVISORY_COUNTRY_URL}/get`,
    POST: `${CALISTA_ADVISORY_COUNTRY_URL}/create`,
    PUT: `${CALISTA_ADVISORY_COUNTRY_URL}/update`,
    DELETE: `${CALISTA_ADVISORY_COUNTRY_URL}/delete`
}

export default CalistaAdvisoryCountryApiUrls
