const COMMON_URL = `${process.env.REACT_APP_EDI_ORIGIN_URL}`

const EVGMApiUrls = {
    EVGM_LIST: `${COMMON_URL}/search-evgm/evgm-list/get`,
    PARTY_LIST_EVGM_COUNT: `${COMMON_URL}/search-evgm/party-list-evgm-count/get`,
    EVGM_STATUS_LIST: `${COMMON_URL}/search-evgm/evgm-status-list/get`,
    SORTING_TYPE: `${COMMON_URL}/search-evgm/sorting-type/get`,

    SAVE_DRAFT: `${COMMON_URL}/evgm/hdr/draft/create`,
    REVIEW: `${COMMON_URL}/evgm/hdr/review/create`,
    SUBMIT: `${COMMON_URL}/evgm/hdr/submit/create`,
    COPY_EVGM: `${COMMON_URL}/evgm/hdr/copy/create`,
};

export default EVGMApiUrls