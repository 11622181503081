const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const CecDoUomMappingApiUrls = {

    //UOM Conversion
    GET_DO_UOM_MAPPING: `${SCO_URL}/cec/douom/map/get`,
    CREATE_DO_UOM_MAPPING: `${SCO_URL}/cec/douom/map/create`,
    EDIT_DO_UOM_MAPPING: `${SCO_URL}/cec/douom/map/update`,
    List_DO_UOM_MAPPING: `${SCO_URL}/cec/douom/map/get`,
    DELETE_DO_UOM_MAPPING: `${SCO_URL}/cec/douom/map/delete`,
}

export default CecDoUomMappingApiUrls