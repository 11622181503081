const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/notification`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const NotificationSubscriptionApiUrls = {
  GET_PARTY: `${USER_RESOURCE_URL}/party/get`,
  GET_BY_ID: `${COMMON_URL}/notification-subscription/hdr/getById`,
  GET: `${COMMON_URL}/notification-subscription/hdr/get`,
  POST: `${COMMON_URL}/notification-subscription/hdr/create`,
  PUT: `${COMMON_URL}/notification-subscription/hdr/update`,
  DELETE: `${COMMON_URL}/notification-subscription/hdr/delete`,
  EXPORT: `${COMMON_URL}/notification-subscription/hdr/download`
}

export default NotificationSubscriptionApiUrls
