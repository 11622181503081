const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/vs-migration-sync-log`

const VsMigrationSyncLogApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
};

export default VsMigrationSyncLogApiUrls
