const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const TcalAsUserGroupMasterApiUrls = {
  GET: `${COMMON_URL}/tcal-as-user-group-master/hdr/get`,
  POST: `${COMMON_URL}/tcal-as-user-group-master/hdr/create`,
  PUT: `${COMMON_URL}/tcal-as-user-group-master/hdr/update`,
  DELETE: `${COMMON_URL}/tcal-as-user-group-master/hdr/delete`,
  EXPORT: `${COMMON_URL}/tcal-as-user-group-master/hdr/download`
};

export default TcalAsUserGroupMasterApiUrls
