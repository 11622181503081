const PING_SHIPMENT_EVENT_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/shipment-event`

const PingShipmentEventApiUrls = {
    GET: `${PING_SHIPMENT_EVENT_URL}/get`,
    POST: `${PING_SHIPMENT_EVENT_URL}/create`,
    PUT: `${PING_SHIPMENT_EVENT_URL}/update`,
    DELETE: `${PING_SHIPMENT_EVENT_URL}/delete`
}

export default PingShipmentEventApiUrls
