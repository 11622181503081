const PING_EVENT_CODE_MAPPING_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/event-code-mapping`

const PingEventCodeMappingApiUrls = {
    GET: `${PING_EVENT_CODE_MAPPING_URL}/get`,
    POST: `${PING_EVENT_CODE_MAPPING_URL}/create`,
    PUT: `${PING_EVENT_CODE_MAPPING_URL}/update`,
    DELETE: `${PING_EVENT_CODE_MAPPING_URL}/delete`
}

export default PingEventCodeMappingApiUrls
