const SCO_JM_CUSTOM_ATTR_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/jm-custom-attribute`
const SCO_JM_CUSTOM_ATTR_VALUE_URL = `${SCO_JM_CUSTOM_ATTR_URL}/config-value`

const GET = `get`;
const UPDATE = `update`;
const DELETE = `delete`;
const CREATE = `create`


const CustomAttributeUrls = {
  CUSTOM_ATTR_GET : `${SCO_JM_CUSTOM_ATTR_URL}/${GET}`,
  CUSTOM_ATTR_UPADTE : `${SCO_JM_CUSTOM_ATTR_URL}/${UPDATE}`,
  CUSTOM_ATTR_DELETE : `${SCO_JM_CUSTOM_ATTR_URL}/${DELETE}`,
  CUSTOM_ATTR_CREATE : `${SCO_JM_CUSTOM_ATTR_URL}/${CREATE}`,
  CUSTOM_ATTR_VALUE_GET : `${SCO_JM_CUSTOM_ATTR_VALUE_URL}/${GET}`,
  CUSTOM_ATTR_VALUE_UPDATE : `${SCO_JM_CUSTOM_ATTR_VALUE_URL}/${UPDATE}`,
  CUSTOM_ATTR_VALUE_DELETE : `${SCO_JM_CUSTOM_ATTR_VALUE_URL}/${DELETE}`,
  CUSTOM_ATTR_VALUE_CREATE : `${SCO_JM_CUSTOM_ATTR_VALUE_URL}/${CREATE}`
}

export default CustomAttributeUrls