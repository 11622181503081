const PING_CG_MIGRATION_BASE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/migration`

const PingCgMigrationApiUrls = {
    CG_BOOKING_MILESTONE_GET: `${PING_CG_MIGRATION_BASE_URL}/cg-booking-milestone/get`,
    CG_BOOKING_GET: `${PING_CG_MIGRATION_BASE_URL}/cg-booking/get`,
    CG_SHIPPING_INFO_GET: `${PING_CG_MIGRATION_BASE_URL}/cg-shipping-info/get`,

    MIGRATION_QUEUE_GET: `${PING_CG_MIGRATION_BASE_URL}/migration-queue/get`,
    MIGRATION_BATCH_LOG_GET: `${PING_CG_MIGRATION_BASE_URL}/migration-batch-log/get`,
    MIGRATION_LOG_GET: `${PING_CG_MIGRATION_BASE_URL}/migration-log/get`,

    PATCH_BATCH_LOG_GET: `${PING_CG_MIGRATION_BASE_URL}/patch-batch-log/get`,
    PATCH_LOG_GET: `${PING_CG_MIGRATION_BASE_URL}/patch-log/get`
}

export default PingCgMigrationApiUrls
