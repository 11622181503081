const UPLOAD_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/schedule-doc`
const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/voyage`

const TcalVsManageScheduleApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`,
  MULTIPLE_DELETE: `${COMMON_URL}/multiple/delete`,
  DOCUMENT_UPLOAD: `${UPLOAD_URL}/upload/create`,
  DOCUMENT_QUERY: `${UPLOAD_URL}/query/get`,
  DOCUMENT_DELETE: `${UPLOAD_URL}/delete`,
  DOCUMENT_LOG_DOWNLOAD: `${UPLOAD_URL}/download-log/get`,
  DOCUMENT_GET: `${UPLOAD_URL}/get`,
  DOCUMENT_PUBLISH: `${UPLOAD_URL}/publish/create`,
  DOCUMENT_MULTIPLE_DELETE: `${UPLOAD_URL}/multiple/delete`,
  CREATE: `${COMMON_URL}/details/create`,
  GET_BY_ID: `${COMMON_URL}/details/getById`,
  UPDATE: `${COMMON_URL}/details/update`,
  MULTIPLE_DELETE: `${COMMON_URL}/details/delete`,
}

export default TcalVsManageScheduleApiUrls
