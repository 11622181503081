const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const CompanyCustomRulePermissionApiUrls = {
  /*GET: `${COMMON_URL}/access-level-company-custom-rule/company-custom-rule-permission/get`,
  POST: `${COMMON_URL}/access-level-company-custom-rule/company-custom-rule-permission/create`,
  PUT: `${COMMON_URL}/access-level-company-custom-rule/company-custom-rule-permission/update`,
  DELETE: `${COMMON_URL}/access-level-company-custom-rule/company-custom-rule-permission/delete`,
  EXPORT: `${COMMON_URL}/access-level-company-custom-rule/company-custom-rule-permission/download`*/
  GET: `${COMMON_URL}/access-level-rule/access-level-rule-permission/getByParty`,
  POST: `${COMMON_URL}/access-level-rule/access-level-rule-permission/createByParty`,
  PUT: `${COMMON_URL}/access-level-rule/access-level-rule-permission/updateByParty`,
  DELETE: `${COMMON_URL}/access-level-rule/access-level-rule-permission/deleteByParty`,
  EXPORT: `${COMMON_URL}/access-level-rule/access-level-rule-permission/downloadByParty`
}

export default CompanyCustomRulePermissionApiUrls
