const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const AccessLevelCompanyCustomRuleApiUrls = {
  GET: `${COMMON_URL}/access-level-rule/hdr/getByParty`,
  POST: `${COMMON_URL}/access-level-rule/hdr/createByParty`,
  PUT: `${COMMON_URL}/access-level-rule/hdr/updateByParty`,
  DELETE: `${COMMON_URL}/access-level-rule/hdr/deleteByParty`,
  EXPORT: `${COMMON_URL}/access-level-company-custom-rule/hdr/download`
}

export default AccessLevelCompanyCustomRuleApiUrls
