// Update URL here after backend ready
const COMMON_URL = `${process.env.REACT_APP_SI_ORIGIN_URL}`

const ShippingInstructionApiUrls = {
  SORTING_TYPE: `${COMMON_URL}/shippinginfo/sorting-type/get`,
  SI_STATUS_LIST: `${COMMON_URL}/shippinginfo/status-list/get`,
  BL_APPLICATION_TYPE_LIST: `${COMMON_URL}/shippinginfo/application-list-shipping-count/get`,
  SI_CARRIER_LIST: `${COMMON_URL}/shippinginfo/carrier-list-shipping-count/get`,
  SAVE_DRAFT: `${COMMON_URL}/shippinginfo/draft/create`,
  SHIPPING_LIST: `${COMMON_URL}/shippinginfo/shipping-list/get`,
  PREVIEW_SI: `${COMMON_URL}/si-template/preview/get`,
  PRINT_BL: `${COMMON_URL}/si-template/print/get`,
  SI_DETAILS: `${COMMON_URL}/shippinginfo/details/getById`,
  COPY_SI: `${COMMON_URL}/shippinginfo/copy/create`,
  SHIPPER_SUBMIT_SI: `${COMMON_URL}/shippinginfo/shipper/submit`,
  SHIPPER_UPDATE_SUBMIT_SI: `${COMMON_URL}/shippinginfo/shipper/updateSubmit`,
  SHIPPER_CONFIRM_SI: `${COMMON_URL}/shippinginfo/shipper/confirm`,
  SHIPPER_UPDATE_CONFIRM_SI: `${COMMON_URL}/shippinginfo/shipper/updateConfirm`,
  SHIPPER_UPDATE_SI: `${COMMON_URL}/shippinginfo/shipper/update`,
  REVIEW_SI: `${COMMON_URL}/shippinginfo/review/create`,
  CANCEL_SI: `${COMMON_URL}/shippinginfo/cancel/update`,
  ARCHIVE_SI: `${COMMON_URL}/shippinginfo/archive/update`,
  DELETE_SI: `${COMMON_URL}/shippinginfo/delete/update`,
  SHIPPER_SURRENDER_SI: `${COMMON_URL}/shippinginfo/shipper/surrenderbl`,
  CARRIER_CONFIRM_SI: `${COMMON_URL}/shippinginfo/carrier/confirm`,
  CARRIER_UPDATE_SI: `${COMMON_URL}/shippinginfo/carrier/update`,
  CARRIER_UPDATE_CONFIRM_SI: `${COMMON_URL}/shippinginfo/carrier/updateConfirm`,
  CARRIER_SEND_SI: `${COMMON_URL}/shippinginfo/carrier/send`,
  CARRIER_RETRACT_SI: `${COMMON_URL}/shippinginfo/carrier/retract`,
  CARRIER_APPROVE_SI: `${COMMON_URL}/shippinginfo/carrier/approve`,
  CARRIER_REJECT_SI: `${COMMON_URL}/shippinginfo/carrier/reject`,
  CARRIER_ISSUE_BL: `${COMMON_URL}/shippinginfo/carrier/issuebl`,
  CARRIER_REVOKE_BL: `${COMMON_URL}/shippinginfo/carrier/revokebl`,
  CARRIER_REVOKE_ISSUED_BL: `${COMMON_URL}/shippinginfo/carrier/revokeissuedbl`,
  DOCUMENT_UPLOAD: `${COMMON_URL}/document/upload/create`,
  DOCUMENT_DOWNLOAD: `${COMMON_URL}/document/download/get`,
  UPLOAD_SI_UPLOAD: `${COMMON_URL}/upload-si/upload/create`,
  UPLOAD_SI_UPLOAD_CARGO: `${COMMON_URL}/upload-si/upload-cargo/create`,
  UPLOAD_SI_QUERY: `${COMMON_URL}/upload-si/query/get`,
  UPLOAD_SI_DELETE: `${COMMON_URL}/upload-si/delete`,
  UPLOAD_SI_DOWNLOAD: `${COMMON_URL}/upload-si/download/get`,
  UPLOAD_SI_DOWNLOAD_LOG: `${COMMON_URL}/upload-si/download-log/get`,
  UPLOAD_SI_CARGO_UPLOAD: `${COMMON_URL}/upload-si/upload-cargo/create`,
  HELPDESK_STATUS_LIST: `${COMMON_URL}/shippinginfo/helpdesk/status-list/get`,
  HELPDESK_SHIPPING_LIST: `${COMMON_URL}/shippinginfo/helpdesk/shipping-list/get`,
  HELPDESK_EXPORT_SHIPPING_LIST: `${COMMON_URL}/shippinginfo/helpdesk/export-shipping-list/get`,
  HELPDESK_SI_DETAILS: `${COMMON_URL}/shippinginfo/helpdesk/details/getById`,
  HELPDESK_SI_CARRIER_LIST: `${COMMON_URL}/shippinginfo/helpdesk/carrier-list-shipping-count/get`,
  HELPDESK_BL_APPLICATION_TYPE_LIST: `${COMMON_URL}/shippinginfo/helpdesk/application-list-shipping-count/get`,
  PROXY_PRINT_VERSION: `https://127.0.0.1:50000/version`,
  PROXY_PRINT_QUERY_PRINTER: `https://127.0.0.1:50000/queryPrinters`,
  PROXY_PRINT_SEND_PRINT: `https://127.0.0.1:50000/print`,
  GET_SI_CONFIG: `${COMMON_URL}/config/hdr/get`,
  SSO_ESSDOCS: `${COMMON_URL}/shippinginfo/sso-essdocs`,
  PUSH_ESSDOCS: `${COMMON_URL}/cargodocs/doc/create`
}

export default ShippingInstructionApiUrls
