const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/preferred-carrier-port/hdr`

const TcalVsManagePreferredPortPairsApiUrls = {
  GET: `${COMMON_URL}/get`,
  PREFERRED_PORT_PAIRS_GET: `${COMMON_URL}/preferred-port-pairs/get`,
  POST: `${COMMON_URL}/create`,
  MULTIPLE_POST: `${COMMON_URL}/multiple/create`,
  MULTIPLE_DELETE: `${COMMON_URL}/multiple/delete`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
}

export default TcalVsManagePreferredPortPairsApiUrls
