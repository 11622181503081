const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const CompanyShipperRequestTypeApiUrls = {
  GET: `${COMMON_URL}/company-for-shipper-request/company-shipper-request-type/get`,
  POST: `${COMMON_URL}/company-for-shipper-request/company-shipper-request-type/create`,
  PUT: `${COMMON_URL}/company-for-shipper-request/company-shipper-request-type/update`,
  DELETE: `${COMMON_URL}/company-for-shipper-request/company-shipper-request-type/delete`,
  EXPORT: `${COMMON_URL}/company-for-shipper-request/company-shipper-request-type/download`
}

export default CompanyShipperRequestTypeApiUrls
