import EndPoints from 'src/constants/CommonEndPoints'

const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const RESOURCE_PATH = `${CEC_URL}/shipment-providers/default-party-info`

const ShipmentProviderDefaultPartyUrls = {
  GET: `${RESOURCE_PATH}/${EndPoints.GET}`,
  GET_BY_SHIPMENT_PROVIDER_ID_AND_PARTY_TYPE: `${RESOURCE_PATH}/getByShipmentProviderIdAndPartyType`,
  GET_IDS_BY_SHIPMENT_PROVIDER_ID: `${RESOURCE_PATH}/getIdsByShipmentProviderId`,
  CREATE: `${RESOURCE_PATH}/${EndPoints.CREATE}`,
  UPDATE: `${RESOURCE_PATH}/${EndPoints.UPDATE}`,
  DELETE: `${RESOURCE_PATH}/${EndPoints.DELETE}`
}

export default ShipmentProviderDefaultPartyUrls
