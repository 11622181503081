const COMMON_URL = `${process.env.REACT_APP_FH_ORIGIN_URL}`

const ForwardHubApiUrls = {
  PROD_MGT_GET: `${COMMON_URL}/prod-mgt/get`,
  PROD_MGT_SEARCH: `${COMMON_URL}/prod-mgt/search/get`,	
  PROD_MGT_POST: `${COMMON_URL}/prod-mgt/createProduct`,
  PROD_MGT_PUT: `${COMMON_URL}/prod-mgt/updateProduct`,
  PROD_MGT_DELETE: `${COMMON_URL}/prod-mgt/deleteProduct`,
  PROD_MGT_EXPORT: `${COMMON_URL}/prod-mgt/download`,
  USER_GROUP_CURR_USER: `${COMMON_URL}/prod-mgt/userGroupsCurrUser/get`,

  OUTBOUND_PAGE_CREATE: `${COMMON_URL}/order-request/create.cl`,
  OUTBOUND_PAGE_SEARCH: `${COMMON_URL}/order-request/search.cl`,
  OUTBOUND_PAGE_UPLOAD: `${COMMON_URL}/order-request-upload/upload.cl`,
  OUTBOUND_PAGE_SHOW_TAB: `${COMMON_URL}/order-request/show-tab/get`,

  VOLUME_REPORT_PAGE: `${COMMON_URL}/volume-report/load`,
  DASHBOARD_PAGE_ENHANCED: `${COMMON_URL}/forwardhub/dashboard/load.cl`,
  DASHBOARD_PAGE_BASIC: `${COMMON_URL}/forwardhub/dashboard/load-basic.cl`,

  INBOUND_PAGE_NORMAL: `${COMMON_URL}/inbound-instr/upload.cl`,
  INBOUND_PAGE_LATE: `${COMMON_URL}/inbound-instr/upload-late.cl`,
  INBOUND_PAGE_REINSTATE: `${COMMON_URL}/inbound-instr/upload-late-reinstate.cl`,
  INBOUND_PAGE_SHOW_TAB: `${COMMON_URL}/inbound-instr/show-tab/get`,

  STOCK_PAGE_UPLOAD: `${COMMON_URL}/stock-management/upload.cl`,
  STOCK_PAGE_SEARCH: `${COMMON_URL}/stock-management/search.cl`,
  STOCK_PAGE_SHOW_TAB: `${COMMON_URL}/stock-management/show-tab/get`,
  STOCK_PAGE_TRANSFER: `${COMMON_URL}/stock-management/transfer.cl`,

  FTM_PAGE_LOAD: `${COMMON_URL}/ftm-request/load`,
  FTM_PAGE_SEARCH: `${COMMON_URL}/ftm-request/loadEnquire`,

  JIT_PAGE_LOAD: `${COMMON_URL}/jit-request/load`,
  JIT_PAGE_SEARCH: `${COMMON_URL}/jit-request/loadEnquire`,

  WAREHOUSETRACKING_PAGE_LOAD: `${COMMON_URL}/warehouse-tracking/load`,
  WAREHOUSETRACKING_PAGE_SEARCH: `${COMMON_URL}/warehouse-tracking/loadEnquire`,

  ADMIN_LOG_OUTBOUND_GET: `${COMMON_URL}/adminlog/outbound/get`,
  ADMIN_LOG_OUTBOUND_POST: `${COMMON_URL}/adminlog/outbound/create`,
  ADMIN_LOG_OUTBOUND_PUT: `${COMMON_URL}/adminlog/outbound/update`,
  ADMIN_LOG_OUTBOUND_DELETE: `${COMMON_URL}/adminlog/outbound/delete`,
  ADMIN_LOG_OUTBOUND_EXPORT: `${COMMON_URL}/adminlog/outbound/download`,
  ADMIN_LOG_OUTBOUND_DOWNLOAD: `${COMMON_URL}/adminlog/outbound/download-file`,

  ADMIN_LOG_INBOUND_GET: `${COMMON_URL}/adminlog/inbound/get`,
  ADMIN_LOG_INBOUND_DOWNLOAD: `${COMMON_URL}/adminlog/inbound/download-file`,

  ADMIN_LOG_STOCKMGT_GET: `${COMMON_URL}/adminlog/stockmgt/get`,
  ADMIN_LOG_STOCKMGT_DOWNLOAD: `${COMMON_URL}/adminlog/stockmgt/download-file`,

  ADMIN_LOG_DASHBOARD_GET: `${COMMON_URL}/adminlog/dashboard/get`,
  ADMIN_LOG_DASHBOARD_DOWNLOAD: `${COMMON_URL}/adminlog/dashboard/download-file`,


  DASH_STATUS_GET: `${COMMON_URL}/dash-load-status/hdr/get`,
  DASH_STATUS_POST: `${COMMON_URL}/dash-load-status/hdr/create`,
  DASH_STATUS_PUT: `${COMMON_URL}/dash-load-status/hdr/update`,
  DASH_STATUS_DELETE: `${COMMON_URL}/dash-load-status/hdr/delete`,
  DASH_STATUS_EXPORT: `${COMMON_URL}/dash-load-status/hdr/download`,


    DASH_USER_STATUS_GET: `${COMMON_URL}/dash-user-load-status/hdr/get`,
    DASH_USER_STATUS_POST: `${COMMON_URL}/dash-user-load-status/hdr/create`,
    DASH_USER_STATUS_PUT: `${COMMON_URL}/dash-user-load-status/hdr/update`,
    DASH_USER_STATUS_DELETE: `${COMMON_URL}/dash-user-load-status/hdr/delete`,
    DASH_USER_STATUS_EXPORT: `${COMMON_URL}/dash-user-load-status/hdr/download`,


    DASH_EXPORT_TEMPLATE_GET: `${COMMON_URL}/dash-export-template/hdr/get`,
    DASH_EXPORT_TEMPLATE_DELETE: `${COMMON_URL}/dash-export-template/hdr/delete`,
    DASH_EXPORT_TEMPLATE_LOAD_ADD: `${COMMON_URL}/forwardhub/dashboard/create-tpl?partyId=`,
    DASH_EXPORT_TEMPLATE_LOAD_EDIT: `${COMMON_URL}/forwardhub/dashboard/edit-tpl?edit=true&tplId=`



}

export default ForwardHubApiUrls
