const CIA_URL = `${process.env.REACT_APP_CIA_ORIGIN_URL}`
const CIA_PUBLIC_URL = `${process.env.PUBLIC_URL}`


const CIAApiUrls = {
  GRISEARCH: `${CIA_URL}/tas/hssearch/gri/grisearch`,
  GRISEARCH_V2: `${CIA_URL}/hs-code/v2/gri-search`,
  COMPLIANCE: `${CIA_URL}/tas/compliancedetail/compliancedetailsearch`,
  COMPLIANCE_V2: `${CIA_URL}/compliance-detail/v2/search`,
  COMPLIANCE_V2_1: `${CIA_URL}/compliance-detail/v2.1/search`,
  TRANSACTION_LIMIT: `${CIA_URL}/tas/common/gettransactionlimit`,
  DEST_CTRY: `${CIA_URL}/tas/common/getTASDestinationCountryList`,
  ORGN_CTRY: `${CIA_URL}/tas/common/getTASOriginCountryList`,
  PARTY_HS_CODE_LIST: `${CIA_URL}/tas/common/partyhscodelist`,
  GET_LANGUAGE: `${CIA_URL}/tas/common/getlang`,
  GET_MGMT_DETAIL: `${CIA_URL}/tas/common/getacctmgmtdetail`,
  GET_ADMIN_MGMT_DETAIL: `${CIA_URL}/tas/common/getadminacctmgmtdetail`,
  GET_ADMIN_MGMT_DETAIL_V2: `${CIA_URL}/acct-mgmt/v2/admin`,

  // LCC
//  LANDED_COST: `/static/tas/lcc/sample_calculation.json`,
  LANDED_COST: `${CIA_URL}/tas/landedcost/v1/landedcost`,
  LANDED_COST_V2: `${CIA_URL}/landed-cost/v2/calculate`,
  INCOTERMS: `${CIA_PUBLIC_URL}/static/tas/lcc/incoterms.json`,
  CURRENCY: `${CIA_PUBLIC_URL}/static/tas/lcc/currency.json`,
  // TODO ref: src\views\intelligent-advisory\lcc\calculator\CargoForm\HsCodeSearch.js:61
  UOM: `${CIA_URL}/tas/common/gethscodeuom`,
  // TODO ref: src\views\intelligent-advisory\lcc\calculator\CargoForm\HsCodeSearch.js:78
  // TODO ref: src\views\intelligent-advisory\lcc\calculator\CargoForm\components\SearchDialog.js:64
  HSCODE: `${CIA_URL}/tas/hssearch/gri/grisearch`,
  EADVISORY_EXPORT_PDF: `${CIA_URL}/tas/compliancedetail/pdf/generalReport`,
  EADVISORY_EXPORT_PDF_V2: `${CIA_URL}/compliance-detail/v2/generate-report/pdf`,
  EADVISORY_EXPORT_EXCEL: `${CIA_URL}/tas/compliancedetail/excel/generalReport`,
  EADVISORY_EXPORT_EXCEL_V2: `${CIA_URL}/compliance-detail/v2/generate-report/excel`,
  LCC_EXPORT_PDF: `${CIA_URL}/tas/landedcost/pdf/generalReport`,
  LCC_EXPORT_EXCEL: `${CIA_URL}/tas/landedcost/excel/generalReport`,

  MASS_RECOMMEND_UPLOAD_DOC: `${CIA_URL}/tas/massrecommend/draft/upload`,
  MASS_RECOMMEND_ANALYSE_DOC: `${CIA_URL}/tas/massrecommend/draft/analyse`,
  MASS_RECOMMEND_FETCH_PARTY: `${CIA_URL}/tas/massrecommend/party`,
  MASS_RECOMMEND_GENERATE_TEMPLATE: `${CIA_URL}/tas/massrecommend/generateTemplate`,
  MASS_RECOMMEND_GENERATE_ERRORLOG: `${CIA_URL}/tas/massrecommend/generateErrorLog`,
  MASS_RECOMMEND_GENERATE_REPORT_EXCEL: `${CIA_URL}/tas/massrecommend/generateReportExcel`,
  MASS_RECOMMEND_UPDATE: `${CIA_URL}/tas/massrecommend/update`,
  
}



export default CIAApiUrls
