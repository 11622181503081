const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const CEC_PARTY_CONFIG = `${CEC_URL}/party-config`;
const CEC_SAVED_ADDRESS = `/saved-address`;
const CEC_WEBHOOK = `/webhook`;

const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`

const GET = `get`;
const UPDATE = `update`;
const DELETE = `delete`;
const CREATE = `create`

const PartyConfigUrls = {
    TPR_PARTY_GET:`${TPR_PARTY_URL}/${GET}`,
    // CeC Saved Address
    CEC_SAVED_ADDRESS: CEC_PARTY_CONFIG+CEC_SAVED_ADDRESS,
    SAVED_ADDRESS_GET : `${CEC_PARTY_CONFIG}${CEC_SAVED_ADDRESS}/${GET}`,
    SAVED_ADDRESS_CREATE : `${CEC_PARTY_CONFIG}${CEC_SAVED_ADDRESS}/${CREATE}`,
    SAVED_ADDRESS_UPDATE: `${CEC_PARTY_CONFIG}${CEC_SAVED_ADDRESS}/${UPDATE}`,
    SAVED_ADDRESS_DELETE : `${CEC_PARTY_CONFIG}${CEC_SAVED_ADDRESS}/${DELETE}`,
    SAVED_SHIPPER_ADDRESS_OF_USER_PARTY : `${CEC_PARTY_CONFIG}${CEC_SAVED_ADDRESS}/current`,

    // CeC Webhook
    CEC_WEBHOOK: CEC_PARTY_CONFIG+CEC_WEBHOOK,
    CEC_WEBHOOK_GET : `${CEC_PARTY_CONFIG}${CEC_WEBHOOK}/${GET}`,
    CEC_WEBHOOK_CREATE : `${CEC_PARTY_CONFIG}${CEC_WEBHOOK}/${CREATE}`,
    CEC_WEBHOOK_UPDATE: `${CEC_PARTY_CONFIG}${CEC_WEBHOOK}/${UPDATE}`,
    CEC_WEBHOOK_DELETE : `${CEC_PARTY_CONFIG}${CEC_WEBHOOK}/${DELETE}`,
}

export default PartyConfigUrls
