const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/preferred-carrier/hdr`

const TcalVsManageBlacklistedCarriersApiUrls = {
  GET: `${COMMON_URL}/get`,
  BLACKLISTED_CARRIERS_GET: `${COMMON_URL}/blacklisted-carriers/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
}

export default TcalVsManageBlacklistedCarriersApiUrls
