const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/notification`

const NotificationTriggerApiUrls = {
  GET: `${COMMON_URL}/notification-trigger/hdr/get`,
  POST: `${COMMON_URL}/notification-trigger/hdr/create`,
  PUT: `${COMMON_URL}/notification-trigger/hdr/update`,
  DELETE: `${COMMON_URL}/notification-trigger/hdr/delete`,
  EXPORT: `${COMMON_URL}/notification-trigger/hdr/download`
};

export default NotificationTriggerApiUrls
