const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report`

const ReportManagementApiUrls = {
    GET_BY_ID: `${COMMON_URL}/report-management/hdr/getById`,
    GET_BY_USER_ID: `${COMMON_URL}/report-management/hdr/getByUserId`,
    GET: `${COMMON_URL}/report-management/hdr/get`,
    POST: `${COMMON_URL}/report-management/hdr/create`,
    PUT: `${COMMON_URL}/report-management/hdr/update`,
    DELETE: `${COMMON_URL}/report-management/hdr/delete`,
    EXPORT: `${COMMON_URL}/report-management/hdr/download`
}

export default ReportManagementApiUrls
