const TMP_URL = `${process.env.REACT_APP_TMP_ORIGIN_URL}/admin/company`

const TmpCompanyProfileApiUrls = {

    //Company Profile
    GET: `${TMP_URL}/get`,
    CREATE: `${TMP_URL}/create`,
    EDIT: `${TMP_URL}/update`,
    LIST: `${TMP_URL}/get`,
    DELETE: `${TMP_URL}/delete`,
}

export default TmpCompanyProfileApiUrls