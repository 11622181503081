const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const AccessLevelCompanyRuleApiUrls = {
  GET_By_ID: `${COMMON_URL}/access-level-company/access-level-company-rule/getById`,
  GET: `${COMMON_URL}/access-level-company/access-level-company-rule/get`,
  POST: `${COMMON_URL}/access-level-company/access-level-company-rule/create`,
  PUT: `${COMMON_URL}/access-level-company/access-level-company-rule/update`,
  DELETE: `${COMMON_URL}/access-level-company/access-level-company-rule/delete`,
  EXPORT: `${COMMON_URL}/access-level-company/access-level-company-rule/download`
}

export default AccessLevelCompanyRuleApiUrls
