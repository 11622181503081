import AccountSetupRoutes from 'src/routes/AccountSetup'
import CalistaAdminRoutes from 'src/routes/CalistaAdmin'
import CalistaAdvisoryRoutes from 'src/routes/CalistaAdvisory'
import ClogsConfigRoute from 'src/routes/LogisticsAdmin'
import EdiRoutes from 'src/routes/EDI'
import FreightBookingRoutes from 'src/routes/FreightBooking'
import HelpdeskRoute from 'src/routes/HelpDesk'
import NotificationRoutes from 'src/routes/Notification'
import ReportRoute from 'src/routes/Report'
import PingAdminRoutes from 'src/routes/PingAdmin'
import PingRoutes from 'src/routes/Ping'
import RiceStockRoutes from 'src/routes/Ricestock'
import SCOJobRoutes from 'src/routes/SCOJob'
import ShippingInstrutionRoutes from 'src/routes/ShippingInstruction'
import VesselScheduleRoutes from 'src/routes/VesselSchedule'
import ForwardHubRoutes from 'src/routes/ForwardHub'
import CompanyPreferenceRoutes from 'src/routes/CompanyPreference'
import CalistaAdvisoryAdminRoutes from 'src/routes/CalistaAdvisoryAdmin'
import CalistaPartyCompanyAdminRoutes from 'src/routes/CalistaPartyCompanyAdmin'
import CalistaAdvisoryAcctMgmtRoutes from 'src/routes/CalistaAdvisoryAcctMgmt'
import NmotRoutes from 'src/routes/Nmot'
import UserPreferenceRoutes from 'src/routes/UserPreference'
import CarbonFootprintRoutes from 'src/routes/CarbonFootprint'
import SCOInvMgmtRoutes from 'src/routes/SCOInvMgmt'
import SCOAdminRoutes from 'src/routes/SCOAdmin'
import CecAccountRoute from 'src/routes/CecAccount'
import SCORateMgmtRoutes from 'src/routes/SCORateMgmt'
import CecAdminRoute from 'src/routes/CecAdmin'
import EcommerceRoute from 'src/routes/Ecommerce'
import CalistaECommerce from 'src/routes/CalistaECommerce'
import HomePageRoute from 'src/routes/Home'
import ComplianceRoutes from 'src/routes/Compliance'
import EvgmRoutes from 'src/routes/Evgm'
import EssDocsRoutes from 'src/routes/EssDocs'
import TmpAdminRoute from 'src/routes/TmpAdmin'
import TradeManagementRoutes from 'src/routes/TradeManagement'

const routes = [
  ...VesselScheduleRoutes,
  ...AccountSetupRoutes,
  ...CalistaAdminRoutes,
  ...CalistaAdvisoryRoutes,
  ...NotificationRoutes,
  ...ReportRoute,
  ...FreightBookingRoutes,
  ...EdiRoutes,
  ...SCOJobRoutes,
  ...PingAdminRoutes,
  ...PingRoutes,
  ...RiceStockRoutes,
  ...HelpdeskRoute,
  ...ClogsConfigRoute,
  ...ShippingInstrutionRoutes,
  ...CompanyPreferenceRoutes,
  ...ForwardHubRoutes,
  ...CalistaAdvisoryAdminRoutes,
  ...CalistaPartyCompanyAdminRoutes,
  ...CalistaAdvisoryAcctMgmtRoutes,
  ...NmotRoutes,
  ...UserPreferenceRoutes,
  ...SCOInvMgmtRoutes,
  ...CarbonFootprintRoutes,
  ...SCOAdminRoutes,
  ...CecAccountRoute,
  ...SCORateMgmtRoutes,
  ...CecAdminRoute,
  ...EcommerceRoute,
  ...CalistaECommerce,
  ...HomePageRoute,
  ...ComplianceRoutes,
  ...EvgmRoutes,
  ...EssDocsRoutes,
  ...TmpAdminRoute,
  ...TradeManagementRoutes
]

class CngRoute {
  getConfigurations() {
    return getChildRouteConfigurations()
  }
}

function getChildRouteConfigurations() {
  console.log(routes)

  return routes.reduce((previousRoutes, route) => {
    let currentRoutes = previousRoutes.concat(route.getConfigurations())
    return currentRoutes
  }, [])
}

export default CngRoute
