const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const SHIPMENT_PROVIDERS = `${CEC_URL}/shipment-providers`;
const GET = `get`;
const UPDATE = `update`;
const DELETE = `delete`;
const CREATE = `create`

const ShipmentProviderUrls = {
    SHIPMENT_PROVIDERS: SHIPMENT_PROVIDERS,
    GET : `${SHIPMENT_PROVIDERS}/${GET}`,
    CREATE : `${SHIPMENT_PROVIDERS}/${CREATE}`,
    UPDATE: `${SHIPMENT_PROVIDERS}/${UPDATE}`,
    DELETE : `${SHIPMENT_PROVIDERS}/${DELETE}`,
    GET_FOR_USER_PARTY : `${SHIPMENT_PROVIDERS}/current`,
}

export default ShipmentProviderUrls
