const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/nbs-charge-code-mapping`

const NBSChargeCodeMappingApiUrls = {
    GET: `${COMMON_URL}/get`,
    POST: `${COMMON_URL}/create`,
    PUT: `${COMMON_URL}/update`,
    DELETE: `${COMMON_URL}/delete`
}

export default NBSChargeCodeMappingApiUrls
