import ConnectorAppsApiUrls from './ConnectorAppsApiUrls';

const CREDENTIALS = `credentials`;
const GET = `get`;
const CREATE = `create`;
const UPDATE = `update`;
const DELETE = `delete`;

const ConnectorCredentialsApiUrls = (connectorAppId) => {
    return {
        GET: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CREDENTIALS}/${GET}`,
        CREATE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CREDENTIALS}/${CREATE}`,
        UPDATE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CREDENTIALS}/${UPDATE}`,
        DELETE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CREDENTIALS}/${DELETE}`,
    }
};

export default ConnectorCredentialsApiUrls;

