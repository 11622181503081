const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`
const DELIVERY_ORDERS_UI= `${CEC_URL}/ecommerce/ui/delivery-orders`;
const DELIVERY_ORDERS_API= `${CEC_URL}/ecommerce/delivery-order`;
const DELIVERY_ORDERS_LABEL_API= `${CEC_URL}/ecommerce/delivery-order-label`;
const DELIVERY_ORDERS_MASS_LABEL_API= `${CEC_URL}/ecommerce/mass-label`;
const GET = `get`;
const UPDATE = `update`;
const DELETE = `delete`;

const DeliveryOrderApiUrls = {
    DELIVERY_ORDERS: DELIVERY_ORDERS_UI,
    SEARCH : `${DELIVERY_ORDERS_UI}/${GET}`,
    GET_BY_REF_NO : `${DELIVERY_ORDERS_API}/getByRefNo/`,
    CREATE : `${DELIVERY_ORDERS_API}/createDo`,
    UPDATE_BY_REF_NO: `${DELIVERY_ORDERS_API}/updateDo/`,
    PRINT : `${DELIVERY_ORDERS_LABEL_API}/label`,
    MASS_PRINT: `${DELIVERY_ORDERS_MASS_LABEL_API}/print`,
    GET_MASS_PRINT: `${DELIVERY_ORDERS_MASS_LABEL_API}/get`,
}

export default DeliveryOrderApiUrls
