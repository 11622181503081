const COMMON_URL = `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/json-schema/hdr`

const JsonSchemaApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download`
};

export default JsonSchemaApiUrls
