const PING_EVENT_CODE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/subscription-event`

const PING_EVENT_SUBSCRIPTION = {
    CREATE: `${PING_EVENT_CODE_URL}/create`,
    GET: `${PING_EVENT_CODE_URL}/get`,
    UNSUBSCRIBE: `${PING_EVENT_CODE_URL}/unsubscribe`,
    SUBSCRIBE: `${PING_EVENT_CODE_URL}/subscribe`,
    GET_CODE:`${process.env.REACT_APP_PING_ORIGIN_URL}/ping/code-master/get`

}
export default PING_EVENT_SUBSCRIPTION;