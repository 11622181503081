const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const AccessLevelUserApiUrls = {
  GET_USER: `${USER_RESOURCE_URL}/user/get`,
  GET_USER_DETAIL: `${USER_RESOURCE_URL}/user/detail/get`,
  GET_USER_DETAIL_BY_PARTY: `${COMMON_URL}/access-level-user/hdr/get-users-by-party`,
  GET_USER_PROFILE_BY_PARTY: `${COMMON_URL}/access-level-user/hdr/get-user-profiles-by-party`,
  GET: `${COMMON_URL}/access-level-user/hdr/getById`,
  POST: `${COMMON_URL}/access-level-user/hdr/create`,
  PUT: `${COMMON_URL}/access-level-user/hdr/update`,
  DELETE: `${COMMON_URL}/access-level-user/hdr/delete`,
  EXPORT: `${COMMON_URL}/access-level-user/hdr/download`,
  GET_FILTERED_PARTIES: `${COMMON_URL}/access-level-user/hdr/get-parties-for-filter`
}

export default AccessLevelUserApiUrls
