const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const JOB_ATTRIBUTE_BASE_URL = `${SCO_URL}/jobattribute`

const SCOJobAttributeApiUrls = {
  GET: `${JOB_ATTRIBUTE_BASE_URL}/get`,
  CREATE_JOB_ATTRIBUTE: `${JOB_ATTRIBUTE_BASE_URL}/createJobAttribute`,
  UPDATE_JOB_ATTRIBUTE: `${JOB_ATTRIBUTE_BASE_URL}/updateJobAttribute`,
  DELETE_JOB_ATTRIBUTE: `${JOB_ATTRIBUTE_BASE_URL}/deleteJobAttribute`,
  GET_JOB_ATTRIBUTE_BY_ID: `${JOB_ATTRIBUTE_BASE_URL}/getById`,
  GET_JOB_ATTRIBUTE_OPTION: `${JOB_ATTRIBUTE_BASE_URL}/attributeConfig-dropdown/get`

}




export default SCOJobAttributeApiUrls