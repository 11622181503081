const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}`

const PollingSourceApiUrls = {
  GET: `${COMMON_URL}/polling/polling-source/get`,
  POST: `${COMMON_URL}/polling/polling-source/create`,
  PUT: `${COMMON_URL}/polling/polling-source/update`,
  DELETE: `${COMMON_URL}/polling/polling-source/delete`,
  EXPORT: `${COMMON_URL}/polling/polling-source/download`
}

export default PollingSourceApiUrls
