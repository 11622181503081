const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report`

const ReportSchedulerApiUrls = {
    GET_BY_ID: `${COMMON_URL}/report-scheduler/hdr/getById`,
    GET_BY_USER_ID: `${COMMON_URL}/report-scheduler/hdr/getByUserId`,
    GET: `${COMMON_URL}/report-scheduler/hdr/get`,
    POST: `${COMMON_URL}/report-scheduler/hdr/create`,
    PUT: `${COMMON_URL}/report-scheduler/hdr/update`,
    DELETE: `${COMMON_URL}/report-scheduler/hdr/delete`,
    EXPORT: `${COMMON_URL}/report-scheduler/hdr/download`,
    GET_CUSTOMER: `${COMMON_URL}/report-scheduler/hdr/get-for-customer`,
    // GET_BY_USER_CUSTOMER_ID:''
    POST_CUSTOMER: `${COMMON_URL}/report-scheduler/hdr/create-for-customer`,
    PUT_CUSTOMER: `${COMMON_URL}/report-scheduler/hdr/update-for-customer`,
    DELETE_CUSTOMER: `${COMMON_URL}/report-scheduler/hdr/delete-for-customer`,

}

export default ReportSchedulerApiUrls
