const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const PARTY_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const TtfbTprPartyApiUrls = {
  GET_BY_PARTY_SERVICE: `${PARTY_RESOURCE_URL}/party/get`,
  GET: `${COMMON_URL}/ttfb-tpr-party/hdr/get`,
  GETBYID: `${COMMON_URL}/ttfb-tpr-party/hdr/getById`,
  POST: `${COMMON_URL}/ttfb-tpr-party/hdr/create`,
  PUT: `${COMMON_URL}/ttfb-tpr-party/hdr/update`,
  DELETE: `${COMMON_URL}/ttfb-tpr-party/hdr/delete`,
  EXPORT: `${COMMON_URL}/ttfb-tpr-party/hdr/download`
};

export default TtfbTprPartyApiUrls
