const COMMON_URL = `${process.env.REACT_APP_SI_ORIGIN_URL}`

const ComplianceApiUrls = {
  SORTING_TYPE: `${COMMON_URL}/compliance/sorting-type/get`,
  STATUS_LIST: `${COMMON_URL}/compliance/status-list/get`,
  DEC_TYPE_LIST: `${COMMON_URL}/compliance/decType-list/get`,
  MESSAGE_TYPE_LIST: `${COMMON_URL}/compliance/messageType-list/get`,
  SAVE_DRAFT: `${COMMON_URL}/compliance/draft/create`,
  COMPLIANCE_LIST: `${COMMON_URL}/compliance/compliance-list/get`,
  CREATE_PERMIT: `${COMMON_URL}/compliance/review/create`,
  UPDATE_SUBMIT: `${COMMON_URL}/compliance/updateSubmit`,
  VIEW_PERMIT_BY_ID: `${COMMON_URL}/compliance/details/getById`,
  ENQUIRE_STATUS: `${COMMON_URL}/compliance/enquiry/get`,
  DELETE_DRAFT: `${COMMON_URL}/compliance/delete/update`,

  //DOCUMENT API
  DOCUMENT_UPLOAD: `${COMMON_URL}/document/upload/create`,

}

export default ComplianceApiUrls
