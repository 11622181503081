const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const ShipperRequestTypeApiUrls = {
  GET: `${COMMON_URL}/shipper-request-type/hdr/get`,
  POST: `${COMMON_URL}/shipper-request-type/hdr/create`,
  PUT: `${COMMON_URL}/shipper-request-type/hdr/update`,
  DELETE: `${COMMON_URL}/shipper-request-type/hdr/delete`,
  EXPORT: `${COMMON_URL}/shipper-request-type/hdr/download`
};

export default ShipperRequestTypeApiUrls
