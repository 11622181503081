import ConnectorMappingApiUrls from './ConnectorMappingApiUrls'

const ITEMS = `items`
const GET = `get`
const CREATE = `create`
const UPDATE = `update`
const DELETE = `delete`


const ConnectorMappingItemsApiUrls = (mappingId) => {
  return {
    GET: `${ConnectorMappingApiUrls.CONNECTOR_MAPPINGS}/${mappingId}/${ITEMS}/${GET}`,
    CREATE: `${ConnectorMappingApiUrls.CONNECTOR_MAPPINGS}/${mappingId}/${ITEMS}/${CREATE}`,
    UPDATE: `${ConnectorMappingApiUrls.CONNECTOR_MAPPINGS}/${mappingId}/${ITEMS}/${UPDATE}`,
    DELETE: `${ConnectorMappingApiUrls.CONNECTOR_MAPPINGS}/${mappingId}/${ITEMS}/${DELETE}`
  }
}

export default ConnectorMappingItemsApiUrls