const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}/vessel`

const TcalVsVesselApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  MULTIPLE_DELETE: `${COMMON_URL}/multiple/delete`,
  MULTIPLE_CREATE: `${COMMON_URL}/multiple/create`,
  EXPORT: `${COMMON_URL}/download`
}

export default TcalVsVesselApiUrls
