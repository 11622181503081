const CALISTA_ADVISORY_PARTY_CONFIG_URL = `${process.env.REACT_APP_CIA_ORIGIN_URL}/cia-admin/party-config`
const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`

const CalistaAdvisoryPartyConfigApiUrls = {
    GET_FROM_TPR_PARTY: `${TPR_PARTY_URL}/get`,
    GET: `${CALISTA_ADVISORY_PARTY_CONFIG_URL}/get`,
    PUT: `${CALISTA_ADVISORY_PARTY_CONFIG_URL}/update`
}

export default CalistaAdvisoryPartyConfigApiUrls
