import ConnectorAppsApiUrls from './ConnectorAppsApiUrls';

const CONNECTORS = `connectors`;
const GET = `get`;
const CREATE = `create`;
const UPDATE = `update`;
const DELETE = `delete`;


const ConnectorEndpointApiUrls = (connectorAppId) => {
    return {
        GET: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CONNECTORS}/${GET}`,
        CREATE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CONNECTORS}/${CREATE}`,
        UPDATE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CONNECTORS}/${UPDATE}`,
        DELETE: `${ConnectorAppsApiUrls.CONNECTOR_APPS}/${connectorAppId}/${CONNECTORS}/${DELETE}`,
    }
};

export default ConnectorEndpointApiUrls;