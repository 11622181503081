const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/report`
const REPORT_URL = `${process.env.REACT_APP_COMMON_SERVICE_REPORT_ORIGIN_URL}/report`

const ReportDownloadApiUrls = {
    GET_BY_ID: `${COMMON_URL}/report-download/hdr/getById`,
    GET: `${COMMON_URL}/report-download/hdr/get`,
    POST: `${COMMON_URL}/report-download/hdr/create`,
    DOWNLOAD: `${REPORT_URL}/report-download/hdr/download-report`,
    PUT: `${COMMON_URL}/report-download/hdr/update`,
    DELETE: `${COMMON_URL}/report-download/hdr/delete`,
    EXPORT: `${COMMON_URL}/report-download/hdr/download`,
    GET_PARTY_LIST_BY_TYPE_CODE: `${COMMON_URL}/report-download/hdr/party-list-by-type-code`,
    GET_PARTY_LIST_BY_USER_GROUP_CODE: `${COMMON_URL}/report-download/hdr/party-list-by-user-group-code`,
    GET_CURRENT_USER_PARTY: `${COMMON_URL}/report-download/hdr/get-current-user-party`,
    GET_CURRENT_USER: `${COMMON_URL}/report-download/hdr/get-current-user`
}

export default ReportDownloadApiUrls
