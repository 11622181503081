const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const CecUomConfigApiUrls = {

    //UOM Conversion
    GET_UOM_CONVERSION: `${SCO_URL}/cec/uom/conversion/get`,
    CREATE_UOM_CONVERSION: `${SCO_URL}/cec/uom/conversion/create`,
    EDIT_UOM_CONVERSION: `${SCO_URL}/cec/uom/conversion/update`,
    List_UOM_CONVERSION: `${SCO_URL}/cec/uom/conversion/get`,
    DELETE_UOM_CONVERSION: `${SCO_URL}/cec/uom/conversion/delete`,
}

export default CecUomConfigApiUrls