const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const SHIPMENT_PROVIDER_CONNECTOR_MAPPING = `${CEC_URL}/shipment-providers/connector-mappings`
const GET = `get`
const CREATE = `create`
const UPDATE = `update`
const DELETE = `delete`
const VARIABLES = `variables`

const ShipmentProviderConnectorEndpointVariableConfigUrls = (mappingId) => {
  return {
    SHIPMENT_PROVIDER_CONNECTOR_MAPPING: SHIPMENT_PROVIDER_CONNECTOR_MAPPING,
    GET: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${mappingId}/${VARIABLES}/${GET}`,
    CREATE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${mappingId}/${VARIABLES}/${CREATE}`,
    UPDATE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${mappingId}/${VARIABLES}/${UPDATE}`,
    DELETE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${mappingId}/${VARIABLES}/${DELETE}`
  }
}
export default ShipmentProviderConnectorEndpointVariableConfigUrls