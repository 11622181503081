const MAINTAINANCE_URL = `${process.env.REACT_APP_CODE_MAINTENANCE_ORIGIN_URL}/code-maintenance`
const VESSEL_SEARCH_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}`

const SearchScheduleApiUrls = {
  CARRIER_ENROLL_GET: `${VESSEL_SEARCH_URL}/carrier-enroll/hdr/calista-carriers/get`,
  CONNECTION_REQUEST_CREATE: `${VESSEL_SEARCH_URL}/connection-request/hdr/create`,
  DELETE_RECENT_SEARCH: `${VESSEL_SEARCH_URL}/search-history/hdr/delete`,
  FEEDBACK_CREATE: `${VESSEL_SEARCH_URL}/feedback/hdr/create`,
  MAINTAINANCE_CODE: `${MAINTAINANCE_URL}/get`,
  RECENT_SEARCH: `${VESSEL_SEARCH_URL}/search-history/hdr/recent-search/get`,
  SHIPPER_VESSEL_SCHEDULE: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/shipper/get`,
  SHIPPER_VESSEL_SCHEDULE_LEG: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/shipper/legs/get`,
  SHIPPER_VESSEL_GET_POLLING: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/shipper/polling-progress/get-one`,
  SHIPPER_LIST_CARRIER: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/shipper/list-carrier/get`,
  SHIPPER_VS_EXPORT: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/shipper/export/get`,
  CARRIER_VESSEL_SCHEDULE: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/carrier/get`,
  CARRIER_VESSEL_SCHEDULE_LEG: `${VESSEL_SEARCH_URL}/search-vessel-schedule/hdr/carrier/legs/get`
}

export default SearchScheduleApiUrls
