const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const PARTY_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const CompanyForShipperRequestApiUrls = {
  GET_BY_PARTY_SERVICE: `${PARTY_RESOURCE_URL}/party/get`,
  GET: `${COMMON_URL}/company-for-shipper-request/hdr/get`,
  POST: `${COMMON_URL}/company-for-shipper-request/hdr/create`,
  PUT: `${COMMON_URL}/company-for-shipper-request/hdr/update`,
  DELETE: `${COMMON_URL}/company-for-shipper-request/hdr/delete`,
  EXPORT: `${COMMON_URL}/company-for-shipper-request/hdr/download`
}

export default CompanyForShipperRequestApiUrls
