const CIA_ADMIN_MASS_RECOMMEND_URL = `${process.env.REACT_APP_CIA_ORIGIN_URL}/cia-admin/mass-recommend`

const CiaAdminMassRecommendApiUrls = {
    GET_REQUEST: `${CIA_ADMIN_MASS_RECOMMEND_URL}/request/get`,
    GET_REQUEST_ROW: `${CIA_ADMIN_MASS_RECOMMEND_URL}/request-row/get`,

    // Custom Lookup
    // For TPR Party
    TPR_PARTY_GET: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,

    // For TPR User
    USER_DETAIL_GET: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`
}

export default CiaAdminMassRecommendApiUrls
