const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const SubscriptionApiUrls = {
    GET: `${COMMON_URL}/subscription/hdr/get`,
    POST: `${COMMON_URL}/subscription/hdr/create`,
    PUT: `${COMMON_URL}/subscription/hdr/update`,
    DELETE: `${COMMON_URL}/subscription/hdr/delete`,
    EXPORT: `${COMMON_URL}/subscription/hdr/download`
};

export default SubscriptionApiUrls
