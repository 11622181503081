import { ArticleCategory as Category } from 'src/constants'

const ESSENCE_SERVICES_URL = `${process.env.REACT_APP_SMART_ESSENCE_ORIGIN_URL}/clsmartessence/api/`
const ARTICLES_URL = `${ESSENCE_SERVICES_URL}public/content/articles`
const QUERY_STRING = '?PageSize=3&CurrentPage=1'

const frontEndToApiUrlMap = {
  [Category.ARTICLE]: 'ARTICLE',
  [Category.EVENT]: 'EVENT',
  [Category.NEWS]: 'NEWS',
  [Category.VIDEO]: 'VIDEO'
}

const EssenceApiUrls = Object.freeze({
  ARTICLES: ARTICLES_URL,
  CAROUSEL: `${ESSENCE_SERVICES_URL}public/content/carousel/languageCode/en`,
  CONTENT: `${ESSENCE_SERVICES_URL}public/content/filemanager/content/`,
  ELASTICSEARCH: `${ESSENCE_SERVICES_URL}public/content/elasticsearch`,
  NEWEST_3_ARTICLE: `${ARTICLES_URL}/category/ARTICLE/languageCode/en${QUERY_STRING}`,
  NEWEST_3_EVENT: `${ARTICLES_URL}/category/EVENT/languageCode/en${QUERY_STRING}`,
  NEWEST_3_NEWS: `${ARTICLES_URL}/category/NEWS/languageCode/en${QUERY_STRING}`,
  NEWEST_3_VIDEO: `${ARTICLES_URL}/category/VIDEO/languageCode/en${QUERY_STRING}`,
  QUERY_STRING,
  SITE_CONTENT: `${ESSENCE_SERVICES_URL}public/content/sitecontent/`,
  getArticle: (category) => {
    return `${ARTICLES_URL}/category/${frontEndToApiUrlMap[category]}/languageCode/en`
  },
  getNewest3Article: (category) => {
    return `${ARTICLES_URL}/category/${frontEndToApiUrlMap[category]}/languageCode/en${QUERY_STRING}`
  }
})

export default EssenceApiUrls
