const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const SHIPMENT_PROVIDER_PARTY_MAPPING = `${CEC_URL}/shipment-providers/party-mapping`
const GET = `get`
const CREATE = `create`
const UPDATE = `update`
const DELETE = `delete`

const ShipmentProviderUrls = {
  SHIPMENT_PROVIDER_PARTY_MAPPING: SHIPMENT_PROVIDER_PARTY_MAPPING,
  GET: `${SHIPMENT_PROVIDER_PARTY_MAPPING}/${GET}`,
  CREATE: `${SHIPMENT_PROVIDER_PARTY_MAPPING}/${CREATE}`,
  UPDATE: `${SHIPMENT_PROVIDER_PARTY_MAPPING}/${UPDATE}`,
  DELETE: `${SHIPMENT_PROVIDER_PARTY_MAPPING}/${DELETE}`
}
export default ShipmentProviderUrls