const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`
const TPR_AUTH_URL =`${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth`

const TtfbTprUserApiUrls = {
  GET: `${USER_RESOURCE_URL}/user/get`,
  GET_USER_DETAIL: `${USER_RESOURCE_URL}/user/detail/get`,
  GETBYID: `${COMMON_URL}/ttfb-tpr-user/hdr/getById`,
  POST: `${COMMON_URL}/ttfb-tpr-user/hdr/create`,
  PUT: `${COMMON_URL}/ttfb-tpr-user/hdr/update`,
  DELETE: `${COMMON_URL}/ttfb-tpr-user/hdr/delete`,
  EXPORT: `${COMMON_URL}/ttfb-tpr-user/hdr/download`,
  GET_USER_DETAIL_BY_ROLES: `${TPR_AUTH_URL}/user-detail/by-roles/get`
};

export default TtfbTprUserApiUrls
