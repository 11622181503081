const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}`

const CarrierEnrollApiUrls = {
  GET: `${COMMON_URL}/carrier-enroll/hdr/get`,
  POST: `${COMMON_URL}/carrier-enroll/hdr/create`,
  PUT: `${COMMON_URL}/carrier-enroll/hdr/update`,
  DELETE: `${COMMON_URL}/carrier-enroll/hdr/delete`,
  EXPORT: `${COMMON_URL}/carrier-enroll/hdr/download`
}

export default CarrierEnrollApiUrls
