const COMMON_URL = `${process.env.REACT_APP_VS_ORIGIN_URL}`

const PollingApiUrls = {
  GET: `${COMMON_URL}/polling/hdr/get`,
  POST: `${COMMON_URL}/polling/hdr/create`,
  PUT: `${COMMON_URL}/polling/hdr/update`,
  DELETE: `${COMMON_URL}/polling/hdr/delete`,
  EXPORT: `${COMMON_URL}/polling/hdr/download`
}

export default PollingApiUrls
