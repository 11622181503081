const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`
const COMPANY_RATE_MGMT_BASE_URL = `${SCO_URL}/company`
const COMPANY_RATE_MGMT_CONTRACT_BASE_URL = `${SCO_URL}/company/contract`

const SCORateMgmtApiUrls = {
  /*Start of CONTRACT API*/
  GET_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/get`,
  HDR_GET_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getContract`,
  HDR_CREATE_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/createContract`,
  HDR_UPDATE_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/editContract`,
  DELETE_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/delete`,

  DOCUMENT_UPLOAD:`${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/documents/hdr/create`,
  DOCUMENT_GETBYCONTRACTID: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/documents/getByContractId`,
  DOCUMENT_DOWNLOAD: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/documents/download/get`,

  GET_CONTRACT_RATECARD: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getContract`,
  HDR_UPDATE_CONTRACT_RATECARD: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/rateCard/hdr/saveRateCard`,

  // DOCUMENT_GETBYCONTRACTID: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getContractDocs`,

  HDR_GET_PUBLIC_RATE:  `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getPublicRate`,
  HDR_GET_PREFERENTIAL_RATE:  `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getPreferentialRate`,

  GET_CONTRACT_LIST_DETAILS: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/getListDetail`,
  HDR_DELETE_CONTRACT: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/delete`,
  HDR_UPDATE_CONTRACT_STATUS: `${COMPANY_RATE_MGMT_CONTRACT_BASE_URL}/hdr/status`,
  
  GET_CODE_MASTER_DROPDOWN: `${SCO_URL}/admin/config/code-master-dropdown`,

  GET_CODE_MASTER_LIST : `${SCO_URL}/admin/config/get`,
  GET_TAX: `${COMPANY_RATE_MGMT_BASE_URL}/tax/get`,
  GET_CURRENCY: `${SCO_URL}/currency/get`,
  /*End of CONTRACT API*/
}




export default SCORateMgmtApiUrls