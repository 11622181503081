const PING_EVENT_SOURCE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/event-source`

const PingEventSourceApiUrls = {
    GET: `${PING_EVENT_SOURCE_URL}/get`,
    POST: `${PING_EVENT_SOURCE_URL}/create`,
    PUT: `${PING_EVENT_SOURCE_URL}/update`,
    DELETE: `${PING_EVENT_SOURCE_URL}/delete`
}

export default PingEventSourceApiUrls
