const CALISTA_ADVISORY_FTA_MAPPING_URL = `${process.env.REACT_APP_CIA_ORIGIN_URL}/cia-admin/fta-mapping`

const CalistaAdvisoryFtaMappingApiUrls = {
    GET: `${CALISTA_ADVISORY_FTA_MAPPING_URL}/get`,
    POST: `${CALISTA_ADVISORY_FTA_MAPPING_URL}/create`,
    PUT: `${CALISTA_ADVISORY_FTA_MAPPING_URL}/update`,
    DELETE: `${CALISTA_ADVISORY_FTA_MAPPING_URL}/delete`
}

export default CalistaAdvisoryFtaMappingApiUrls
