const PING_SHIPMENT_EVENT_DETAIL_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/shipment-event-detail`

const PingShipmentEventDetailApiUrls = {
    GET: `${PING_SHIPMENT_EVENT_DETAIL_URL}/get`,
    POST: `${PING_SHIPMENT_EVENT_DETAIL_URL}/create`,
    PUT: `${PING_SHIPMENT_EVENT_DETAIL_URL}/update`,
    DELETE: `${PING_SHIPMENT_EVENT_DETAIL_URL}/delete`
}

export default PingShipmentEventDetailApiUrls
