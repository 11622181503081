const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`
const CEC_QUOTATION = `${CEC_URL}/rate/quote`;

const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`

const GET = `get`;
const UPDATE = `update`;
const DELETE = `delete`;
const CREATE = `create`

const QuotationUrls = {
    TPR_PARTY_GET:`${TPR_PARTY_URL}/${GET}`,
    // CeC Quotation Admin
    QUOTATION_GET : `${CEC_QUOTATION}/${GET}`,
    QUOTATION_CREATE : `${CEC_QUOTATION}/${CREATE}`,
    QUOTATION_UPDATE: `${CEC_QUOTATION}/${UPDATE}`,
    QUOTATION_DELETE : `${CEC_QUOTATION}/${DELETE}`,

    // CeC Quotation Send
    QUOTATION_SEND : `${CEC_QUOTATION}/send`,
}

export default QuotationUrls