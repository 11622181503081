// Update URL here after backend ready
const COMMON_URL = `${process.env.REACT_APP_SI_ORIGIN_URL}`

const DeclarantMaintenanceApiUrls = {
    GET_PARTIES: `${COMMON_URL}/declarant-maintenance/getAllParties`,
    GET_DECLARANT_MAINTENANCE: `${COMMON_URL}/declarant-maintenance/get`,
    GET_DECLARANT_MAINTENANCE_BY_PARTYID: `${COMMON_URL}/declarant-maintenance/getByPartyId/get`,
    CREATE_DECLARANT_MAINTENANCE: `${COMMON_URL}/declarant-maintenance/create`,
    UPDATE_DECLARANT_MAINTENANCE: `${COMMON_URL}/declarant-maintenance/update`,
    DELETE_DECLARANT_MAINTENANCE: `${COMMON_URL}/declarant-maintenance/delete`
}

export default DeclarantMaintenanceApiUrls

  