// Update URL here after backend ready
const COMMON_URL = `${process.env.REACT_APP_FB_ORIGIN_URL}`

const FreightBookingApiUrls = {
  // GET: `${COMMON_URL}/cal-freightbooking/get`,
  // POST: `${COMMON_URL}/cal-freightbooking/create`,
  // PUT: `${COMMON_URL}/cal-freightbooking/update`,
  // DELETE: `${COMMON_URL}/cal-freightbooking/delete`,
  // EXPORT: `${COMMON_URL}/cal-freightbooking/download`,
  SAVE_DRAFT: `${COMMON_URL}/booking/hdr/draft/create`,
  REVIEW_BOOKING: `${COMMON_URL}/booking/hdr/review/create`,
  SUBMIT_BOOKING: `${COMMON_URL}/booking/hdr/submit/create`,
  APPROVE_BOOKING: `${COMMON_URL}/booking/hdr/approve/update`,
  REJECT_BOOKING: `${COMMON_URL}/booking/hdr/reject/update`,
  AMEND_BOOKING: `${COMMON_URL}/booking/hdr/shipper-amend/update`,
  CARRIER_AMEND_BOOKING: `${COMMON_URL}/booking/hdr/carrier-amend/update`,
  APPROVE_AMEND_BOOKING: `${COMMON_URL}/booking/hdr/approve-amend/update`,
  REJECT_AMEND_BOOKING: `${COMMON_URL}/booking/hdr/reject-amend/update`,
  COPY_BOOKING: `${COMMON_URL}/booking/hdr/copy/create`,
  CANCEL_BOOKING: `${COMMON_URL}/booking/hdr/cancel/update`,
  ARCHIVE_BOOKING: `${COMMON_URL}/booking/hdr/archive/update`,
  DELETE_BOOKING: `${COMMON_URL}/booking/hdr/delete/update`,
  BOOKING_GET_BY_ID: `${COMMON_URL}/booking/hdr/details/getById`,
  HELPDESK_BOOKING_GET_BY_ID: `${COMMON_URL}/booking/hdr/details/helpdesk/getById`,
  BOOKING_LIST: `${COMMON_URL}/booking/search-booking/booking-list/get`,
  BOOKING_LIST_ENHANCEMENT: `${COMMON_URL}/booking/search-booking/booking-list-advance/get`,
  HELPDESK_BOOKING_LIST: `${COMMON_URL}/booking/search-booking/helpdesk/booking-list/get`,
  PARTY_LIST_BOOKING_COUNT: `${COMMON_URL}/booking/search-booking/party-list-booking-count/get`,
  HELPDESK_PARTY_LIST_BOOKING_COUNT: `${COMMON_URL}/booking/search-booking/helpdesk/party-list-booking-count/get`,
  HELPDESK_BOOKING_STATUS_LIST: `${COMMON_URL}/booking/search-booking/helpdesk/booking-status-list/get`,
  BOOKING_STATUS_LIST: `${COMMON_URL}/booking/search-booking/booking-status-list/get`,
  SORTING_TYPE: `${COMMON_URL}/booking/search-booking/sorting-type/get`,
  DOCUMENT_UPLOAD: `${COMMON_URL}/document/upload/create`,
  DOCUMENT_DOWNLOAD: `${COMMON_URL}/document/download/get`,
  HELPDESK_AMEND_BOOKING_STATUS: `${COMMON_URL}/booking/hdr/amend-booking-status/helpdesk/update`
}

export default FreightBookingApiUrls
