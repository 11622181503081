const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`

const SCOAdminApiUrls = {
    GET_CODE_MASTER_LIST : `${SCO_URL}/admin/config/get`,
    CREATE_CODE_MASTER : `${SCO_URL}/admin/config/codeMaster`,
    EDIT_CODE_MASTER : `${SCO_URL}/admin/config/codeMaster`,
    DELETE_CODE_MASTER : `${SCO_URL}/admin/config/codeMaster`,

    GET_COUNTRY_LIST: `${SCO_URL}/admin/config/country/get`,
    GET_COUNTRY_STATE_LIST:`${SCO_URL}/admin/config/state/get`,

    GET_TIMEZONE_LIST :`${SCO_URL}/timezone/get`,

    EXPORT: `${SCO_URL}/admin/config/download`
}

export default SCOAdminApiUrls