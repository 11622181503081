const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`
const COMPANY_INV_MGMT_BASE_URL = `${SCO_URL}/company/inventory`
const ADMIN_INV_MGMT_BASE_URL = `${SCO_URL}/admin/inventory`
const COMPANY_SUPPLIER_URL = `${COMPANY_INV_MGMT_BASE_URL}/supplier`
const ADMIN_SUPPLIER_URL = `${ADMIN_INV_MGMT_BASE_URL}/supplier`
const COMPANY_BUYER_URL = `${COMPANY_INV_MGMT_BASE_URL}/buyer`
const ADMIN_BUYER_URL = `${ADMIN_INV_MGMT_BASE_URL}/buyer`
const COMPANY_CATEGORY_URL = `${COMPANY_INV_MGMT_BASE_URL}/category`
const ADMIN_CATEGORY_URL = `${ADMIN_INV_MGMT_BASE_URL}/category`
const COMPANY_WAREHOUSE_URL = `${COMPANY_INV_MGMT_BASE_URL}/warehouse`
const ADMIN_WAREHOSE_URL = `${ADMIN_INV_MGMT_BASE_URL}/warehouse`
const COMPANY_LOCATION_URL = `${COMPANY_INV_MGMT_BASE_URL}/location`
const ADMIN_LOCATION_URL = `${ADMIN_INV_MGMT_BASE_URL}/location`
const COMPANY_SKU_URL = `${COMPANY_INV_MGMT_BASE_URL}/sku`
const ADMIN_SKU_URL = `${ADMIN_INV_MGMT_BASE_URL}/sku`
const COMPANY_DEF_LOC_CONFIG_URL = `${COMPANY_INV_MGMT_BASE_URL}/defLocConfig`
const COMPANY_STOCK_URL = `${COMPANY_INV_MGMT_BASE_URL}/stock`
const COMPANY_ALLOCATION_URL = `${COMPANY_INV_MGMT_BASE_URL}/allocation`
const COMPANY_STOCK_MOVEMENT = `${COMPANY_INV_MGMT_BASE_URL}/stockMovement`
const COMPANY_SKUUOM_URL = `${COMPANY_INV_MGMT_BASE_URL}/skuuom`
const COMPANY_STOCK_COST_DETAILS_URL = `${COMPANY_INV_MGMT_BASE_URL}/stock/costDetails`

const ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL = `${SCO_URL}/admin/inventory/principalmapping`

const MY_INVENTORY_PRINCIPAL_GET_URL = `${COMPANY_INV_MGMT_BASE_URL}/stockprincipal`

const SCOInvMgmtApiUrls = {
  /*Start of COMMON APIs */
  GET_CODE_MASTER_DROPDOWN: `${SCO_URL}/admin/config/code-master-dropdown`,
  GET_PARTY: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
  GET_USER_DETAILS: `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
  /*End of COMMON APIs */

  /*Start of SUPPLIER API*/
  GET_SUPPLIER: `${COMPANY_SUPPLIER_URL}/get`,
  GET_SUPPLIER_BY_ID: `${COMPANY_SUPPLIER_URL}/getById`,
  UPDATE_SUPPLIER: `${COMPANY_SUPPLIER_URL}/updateSupplier`,
  CREATE_SUPPLIER: `${COMPANY_SUPPLIER_URL}/createSupplier`,
  DELETE_SUPPLIER: `${COMPANY_SUPPLIER_URL}/deleteSupplier`,
  ADMIN_GET_SUPPLIER_LIST: `${ADMIN_SUPPLIER_URL}/get`,
  ADMIN_GET_SUPPLIER_BY_ID: `${ADMIN_SUPPLIER_URL}/getById`,
  /*End of SUPPLIER API*/

  /*Start of BUYER API*/
  GET_BUYER: `${COMPANY_BUYER_URL}/get`,
  GET_BUYER_BY_ID: `${COMPANY_BUYER_URL}/getById`,
  UPDATE_BUYER: `${COMPANY_BUYER_URL}/updateBuyer`,
  CREATE_BUYER: `${COMPANY_BUYER_URL}/createBuyer`,
  DELETE_BUYER: `${COMPANY_BUYER_URL}/deleteBuyer`,
  ADMIN_GET_BUYER_LIST: `${ADMIN_BUYER_URL}/get`,
  ADMIN_GET_BUYER_BY_ID: `${ADMIN_BUYER_URL}/getById`,
  GET_BUYER_OPTIONS_LIST: `${COMPANY_BUYER_URL}/getBuyerList`,
  /*End of BUYER API*/

  /*Start of CATEGORY API*/
  GET_CATEGORY: `${COMPANY_CATEGORY_URL}/get`,
  GET_CATEGORY_BY_ID: `${COMPANY_CATEGORY_URL}/getById`,
  UPDATE_CATEGORY: `${COMPANY_CATEGORY_URL}/updateCategory`,
  CREATE_CATEGORY: `${COMPANY_CATEGORY_URL}/createCategory`,
  DELETE_CATEGORY: `${COMPANY_CATEGORY_URL}/deleteCategory`,
  ADMIN_GET_CATEGORY_LIST: `${ADMIN_CATEGORY_URL}/get`,
  ADMIN_GET_CATEGORY_BY_ID: `${ADMIN_CATEGORY_URL}/getById`,
  /*End of CATEGORY API*/

  /* START OF WAREHOUE API */
  GET_WAREHOUSE_LIST: `${COMPANY_WAREHOUSE_URL}/get`,
  CREATE_WAREHOUSE: `${COMPANY_WAREHOUSE_URL}/createWarehouse`,
  GET_WAREHOUSE_TAGS: `${COMPANY_WAREHOUSE_URL}/getAllTags`,
  GET_WAREHOUE_BY_ID: `${COMPANY_WAREHOUSE_URL}/getById`,
  UPDATE_WAREHOUSE: `${COMPANY_WAREHOUSE_URL}/updateWarehouse`,
  DELETE_WAREHOUSE: `${COMPANY_WAREHOUSE_URL}/deleteWarehouse`,
  ADMIN_WAREHOUSE_URL: `${ADMIN_WAREHOSE_URL}/get`,
  ADMIN_WAREHOUSE_BY_ID: `${ADMIN_WAREHOSE_URL}/getById`,

  GET_LOCATION_BY_WAREHOUSE_ID: `${COMPANY_LOCATION_URL}/getLocationByWarehouseId`,
  GET_PARENTLOCATION_BY_WAREHOUSE_ID: `${COMPANY_LOCATION_URL}/getParentLocationByWarehouseId`,
  GET_WAREHOUSE_NAME: `${COMPANY_LOCATION_URL}/getWarehouseName`,
  ADD_STORAGE_LOCATION: `${COMPANY_LOCATION_URL}/addStorageLocation`,
  UPDATE_STORAGE_LOCATION: `${COMPANY_LOCATION_URL}/updateStorageLocation`,
  DELETE_STORAGE_LOCATION: `${COMPANY_LOCATION_URL}/deleteStorageLocation`,

  /* END OF WAREHOUE API */

  /* START OF NONSTORAGE API */
  GET_NONSTORAGE_LOCATION_LIST: `${COMPANY_LOCATION_URL}/get`,
  ADD_NONSTORAGE_LOCATION: `${COMPANY_LOCATION_URL}/addNonStorageLocation`,
  GET_NONSTORAGE_BY_ID: `${COMPANY_LOCATION_URL}/getById`,
  UPDATE_NONSTORAGE_LOCATION: `${COMPANY_LOCATION_URL}/updateNonStorageLocation`,
  DELETE_NONSTORAGE_LOCATION: `${COMPANY_LOCATION_URL}/deleteNonStorageLocation`,
  GET_LOCATION_LIST: `${COMPANY_LOCATION_URL}/getLocationList`,

  ADMIN_LOCATION_URL: `${ADMIN_LOCATION_URL}/get`,
  ADMIN_LOCATION_BY_ID: `${ADMIN_LOCATION_URL}/getById`,


  /* END OF NONSTORAGE API */

  /*Start of SKU API*/
  GET_SKU: `${COMPANY_SKU_URL}/get`,
  GET_SKU_BY_ID: `${COMPANY_SKU_URL}/getById`,
  UPDATE_SKU: `${COMPANY_SKU_URL}/updateSku`,
  GET_CATEGORY_ATTRIBUTE_CONFIG_LIST: `${COMPANY_CATEGORY_URL}/attributeConfig-dropdown/get`,
  CREATE_SKU: `${COMPANY_SKU_URL}/createSku`,
  DELETE_SKU: `${COMPANY_SKU_URL}/deleteSku`,
  ADMIN_GET_SKU_LIST: `${ADMIN_SKU_URL}/get`,
  ADMIN_GET_SKU_BY_ID: `${ADMIN_SKU_URL}/getById`,
  UPLOAD_SKU_DOC: `${COMPANY_SKU_URL}/uploadSku`,
  CREATE_SKU_FROM_UPLOAD: `${COMPANY_SKU_URL}/createSkuFromUpload`,
  GET_PROCESS_UPLOAD_STATUS: `${COMPANY_SKU_URL}/getProcessUploadStatus`,
  GET_CREATE_RECORDS_STATUS: `${COMPANY_SKU_URL}/getCreateRecordsStatus`,
  GET_SKU_ORDER_OPTIONS: `${COMPANY_SKU_URL}/getSKUOrderOptions`,

  GET_WEIGHT_COST_BY_SKUID: `${COMPANY_SKU_URL}/weightCost/getWeightCostBySkuId`,

  // GET_SKU_OPT_TEST: `${COMPANY_SKU_URL}/getSKUOrderOptions`,
  /*End of SKU API*/


  /* Start of SKU UOM  */
  GET_UOM_BY_SKU_ID: `${COMPANY_SKUUOM_URL}/getBySkuId`,
  /* End of SKU UOM  */

  /*Start of Default Location Config API*/
  GET_DEF_LOC_CONFIG: `${COMPANY_DEF_LOC_CONFIG_URL}/get`,
  UPDATE_DEF_LOC_CONFIG: `${COMPANY_DEF_LOC_CONFIG_URL}/update`,
  /*End of Default Location Config API*/

  /*Start of Stock API*/
  GET_STOCK: `${COMPANY_STOCK_URL}/get`,
  GET_STOCK_BY_ID: `${COMPANY_STOCK_URL}/getById`,
  GET_ALLOCATION_LIST: `${COMPANY_ALLOCATION_URL}/get`,
  GET_STOCK_MOVEMENT: `${COMPANY_STOCK_MOVEMENT}/get`,
  GET_STOCK_MOVEMENT_BY_SKUID: `${COMPANY_STOCK_MOVEMENT}/getStockMovementBySkuId`,
  GET_STOCK_DUTY_UNPAID: `${COMPANY_STOCK_URL}/getDUStock`,
  GET_STOCK_DUTY_PAID: `${COMPANY_STOCK_URL}/getDPStock`,
  GET_LOT_NUMBER_BY_SKU_ID: `${COMPANY_STOCK_URL}/getLotNumberBySkuId`,
  GET_AVAILABLE_LOOSE_QTY: `${COMPANY_STOCK_URL}/getAvailableLooseQty`,
  GET_STOCK_MOVEMENT_BY_STOCKID: `${COMPANY_STOCK_MOVEMENT}/getStockMovementByStockId`,
   GET_STOCK_LOT_NUMBER_LIST_BY_PARTY_ID: `${COMPANY_STOCK_URL}/getStockLotNumberListByPartyId`,
  GET_STOCK_SKU_LIST_BY_LOT_NUMBER: `${COMPANY_STOCK_URL}/getSkuListByLotNumber`,
  UPDATE_STOCK_COST_DETAILS_BY_ID: `${COMPANY_STOCK_COST_DETAILS_URL}/updateInventoryCostDetails`,
  GET_STOCK_COST_DETAILS_BY_STOCK_ID: `${COMPANY_STOCK_COST_DETAILS_URL}/getCostDetailsByStockId`,

  /*End of Stock API*/


  /* Start of Principal Mapping API */
  GET_PARTIES: `${ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL}/getAllParties`,
  GET_PRINCIPAL_MAPPING_BY_PARTYID: `${ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL}/get`,
  CREATE_PRINCIPAL_MAPPING: `${ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL}/create`,
  UPDATE_PRINCIPAL_MAPPING: `${ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL}/update`,
  DELETE_PRINCIPAL_MAPPING: `${ADMIN_INV_MGMT_PRINCIPAL_MAPPING_BASE_URL}/delete`,


  /* Start of My Inventory (Principal) */
  GET_MY_INVENTORY_PRINCIPAL: `${MY_INVENTORY_PRINCIPAL_GET_URL}/get`,
  GET_PARTIES_BY_PRICIPALMAPPING: `${MY_INVENTORY_PRINCIPAL_GET_URL}/getPartyByPrincipalMapping`,

  INV_REPORT: `${ADMIN_INV_MGMT_BASE_URL}/report`,
  EXPORT_REPORT: `${ADMIN_INV_MGMT_BASE_URL}/generate-report`,
  GET_INV_REPORTS: `${ADMIN_INV_MGMT_BASE_URL}/getReportList`,
  DOWNLOAD_INV_REPORTS: `${ADMIN_INV_MGMT_BASE_URL}/download-report-file`,
  DELETE_INV_REPORT: `${ADMIN_INV_MGMT_BASE_URL}/deleteReport`,
}




export default SCOInvMgmtApiUrls