const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup/nbs-file`
const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`

const NBSFileApiUrls = {
    GET: `${COMMON_URL}/get`,
    POST: `${COMMON_URL}/create`,
    PUT: `${COMMON_URL}/update`,
    DELETE: `${COMMON_URL}/delete`,
    GET_FROM_TPR_PARTY: `${TPR_PARTY_URL}/get`
}

export default NBSFileApiUrls
