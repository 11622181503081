const PING_PORTNET_LOG_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/portnet`

const PingPortnetLogApiUrls = {
    VESSEL_API_LOG_GET: `${PING_PORTNET_LOG_URL}/vessel-api-log/get`,

    // For CustomLookup for TPR Party
    TPR_PARTY_GET: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`
}

export default PingPortnetLogApiUrls
