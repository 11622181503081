const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const AccessLevelMasterApiUrls = {
  GET: `${COMMON_URL}/access-level-master/hdr/get`,
  POST: `${COMMON_URL}/access-level-master/hdr/create`,
  PUT: `${COMMON_URL}/access-level-master/hdr/update`,
  DELETE: `${COMMON_URL}/access-level-master/hdr/delete`,
  EXPORT: `${COMMON_URL}/access-level-master/hdr/download`
};

export default AccessLevelMasterApiUrls
