const PING_MY_MILESTONE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/my-milestone`
const PING_MY_MILESTONE_UPLOAD_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/my-milestone-upload`

const PingMyMilestoneApiUrls = {
    EVENT_CODE_OPTION_GET: `${PING_MY_MILESTONE_URL}/event-code-option/get`,
    EVENT_LOCODE_OPTION_GET: `${PING_MY_MILESTONE_URL}/event-locode-option/get`,
    FREIGHT_BOOKING_OPTION_GET: `${PING_MY_MILESTONE_URL}/freight-booking-option/get`,
    LOGIN_USER_PARTY_NAME_GET: `${PING_MY_MILESTONE_URL}/login-user-party-name/get`,

    EVENT_CODE_FILTER_OPTION_GET: `${PING_MY_MILESTONE_URL}/event-code-filter-option/get`,
    LIST_GET: `${PING_MY_MILESTONE_URL}/list/get`,
    LIST_COUNT: `${PING_MY_MILESTONE_URL}/list-count/get`,
    DETAILS_GET: `${PING_MY_MILESTONE_URL}/details/get`,
    CREATE: `${PING_MY_MILESTONE_URL}/create`,
    UPDATE: `${PING_MY_MILESTONE_URL}/update`,
    DELETE: `${PING_MY_MILESTONE_URL}/delete`,

    UPLOAD_LIST_GET: `${PING_MY_MILESTONE_UPLOAD_URL}/list/get`,
    UPLOAD_LIST_COUNT: `${PING_MY_MILESTONE_UPLOAD_URL}/list-count/get`,
    UPLOAD_INFO_GET: `${PING_MY_MILESTONE_UPLOAD_URL}/info/get`,
    UPLOAD_DOWNLOAD_DATA_GET: `${PING_MY_MILESTONE_UPLOAD_URL}/download/get`,
    UPLOAD_CREATE: `${PING_MY_MILESTONE_UPLOAD_URL}/create`
}

export default PingMyMilestoneApiUrls
