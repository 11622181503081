const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const TcalPartyCarrierCodeApiUrls = {
  GET: `${COMMON_URL}/tcal-party-carrier-code/hdr/get`,
  POST: `${COMMON_URL}/tcal-party-carrier-code/hdr/create`,
  PUT: `${COMMON_URL}/tcal-party-carrier-code/hdr/update`,
  DELETE: `${COMMON_URL}/tcal-party-carrier-code/hdr/delete`,
  EXPORT: `${COMMON_URL}/tcal-party-carrier-code/hdr/download`,
  GET_FILTERED_PARTIES: `${COMMON_URL}/tcal-party-carrier-code/hdr/get-parties-for-filter`,
};

export default TcalPartyCarrierCodeApiUrls
