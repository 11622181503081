const COMMON_URL = `${process.env.REACT_APP_NMOT_ORIGIN_URL}`

const NMoTApiUrls = {
    ADMIN_GET: `${COMMON_URL}/nmot-users/users-list/get`,
    ADMIN_PUT: `${COMMON_URL}/nmot-users/create`,
    ADMIN_UPDATE: `${COMMON_URL}/nmot-users/update`,
    ADMIN_DELETE: `${COMMON_URL}/nmot-users/delete`,

    H_DEST_GET: `${COMMON_URL}/hinterland-dest/dest-list/get`,
    H_ACTIVE_DEST_GET: `${COMMON_URL}/hinterland-dest/active-dest-list/get`,
    H_DEST_PUT: `${COMMON_URL}/hinterland-dest/create`,
    H_DEST_UPDATE: `${COMMON_URL}/hinterland-dest/update`,
    H_DEST_DELETE: `${COMMON_URL}/hinterland-dest/delete`,

    API_LOG_GET: `${COMMON_URL}/nmot-log/api-logs/get`,
    API_LOG_GET_DISTINCT_USER: `${COMMON_URL}/nmot-log/api-logs/distinct-user/get`,
    NMOT_ADD_PUT_UPDATE: `${COMMON_URL}/nmot-add/create`,
    NMOT_EDIT_UPDATE: `${COMMON_URL}/nmot-add/update`,
    NMOT_DELETE: `${COMMON_URL}/nmot-add/delete`,

    NMOT_UPLOAD_PUT: `${COMMON_URL}/nmot-upload/create`,
    NMOT_UPLOAD_GET: `${COMMON_URL}/nmot-upload/upload-records/get`,
    NMOT_UPLOAD_STATUS_GET: `${COMMON_URL}/nmot-upload/upload-status/get`,

    NMOT_DASHBOARD_GET: `${COMMON_URL}/nmot-dashboard/nmot-records/get`,
    NMOT_DASHBOARD_REFRESH: `${COMMON_URL}/nmot-dashboard/nmot-records/refresh-table`,
    NMOT_DASHBOARD_M_DELETE: `${COMMON_URL}/nmot-dashboard/multiple-records/delete`,
    NMOT_DASHBOARD_ALL_DELETE: `${COMMON_URL}/nmot-dashboard/all-records/delete`,
    NMOT_DASHBOARD_M_ARCHIVE: `${COMMON_URL}/nmot-dashboard/multiple-records/archive`,
    NMOT_DASHBOARD_ALL_ARCHIVE: `${COMMON_URL}/nmot-dashboard/all-records/archive`,
    NMOT_DASHBOARD_DOWNLOAD: `${COMMON_URL}/nmot-dashboard/all-records/download`,

    NMOT_COOKIE_EXPIRED_CONFIG: `${COMMON_URL}/nmot-cookie/expired-config/get`,
    NMOT_COOKIE_AUDIT: `${COMMON_URL}/nmot-cookie/cookie-audit/create`,

}

export default NMoTApiUrls