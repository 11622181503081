const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const AccessLevelUserRuleApiUrls = {
  GET: `${COMMON_URL}/access-level-user/access-level-user-rule/getById`,
  POST: `${COMMON_URL}/access-level-user/access-level-user-rule/create`,
  PUT: `${COMMON_URL}/access-level-user/access-level-user-rule/update`,
  DELETE: `${COMMON_URL}/access-level-user/access-level-user-rule/delete`,
  EXPORT: `${COMMON_URL}/access-level-user/access-level-user-rule/download`
}

export default AccessLevelUserRuleApiUrls
