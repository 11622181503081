const CompanyPreferenceApiUrls = {
  FRB_GET: `${process.env.REACT_APP_FB_ORIGIN_URL}/party-pref/current-party/get`,
  //POST: `${process.env.ORIGIN_URL}/party-pref/create`,
  FRB_PUT: `${process.env.REACT_APP_FB_ORIGIN_URL}/party-pref/update`,
  SI_PREF_CREATE: `${process.env.REACT_APP_SI_ORIGIN_URL}/party-pref/current-party/get`,
  SI_PREF_PUT: `${process.env.REACT_APP_SI_ORIGIN_URL}/party-pref/update`,
  SI_PREF_GET: `${process.env.REACT_APP_SI_ORIGIN_URL}/party-pref/current-party-pref/get`,
  SI_PREF_GET_BY_PARTY: `${process.env.REACT_APP_SI_ORIGIN_URL}/party-pref/get-by-party/get`,
  //DELETE: `${process.env.ORIGIN_URL}/party-pref/delete`,
  //EXPORT: `${process.env.ORIGIN_URL}/party-pref/download`
  COMPANY_CONTACT_SEARCH_CRITERIA: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/search-criteria`,
  COMPANY_CONTACT_SEARCH: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/search`,
  COMPANY_CONTACT_EXPORT: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/export-contacts`,
  COMPANY_CONTACT_GET: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/get`,
  ADD_CONTACT: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/create`,
  UPDATE_CONTACT: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/update`,
  DELETE_CONTACT: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/delete`,
  CUSTOM_DELETE_CONTACT: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/delete-contact`,
  BULK_UPLOAD_SEARCH_CRITERIA: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/search-criteria`,
  BULK_UPLOAD_SEARCH: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/search`,
  BULK_UPLOAD_UPLOAD: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/upload-file`,
  BULK_UPLOAD_DOCUMENT_QUERY: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/query-get`,
  BULK_UPLOAD_DOCUMENT_UPDATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/query-update`,
  BULK_UPLOAD_UPDATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/update`,
  BULK_UPLOAD_DELETE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/delete`,
  BULK_UPLOAD_DOWNLOAD_FILE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/download-file`,
  BULK_UPLOAD_DOWNLOAD_LOG: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/download-log`,
  BULK_UPLOAD_DOWNLOAD_TEMPLATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/bulk-upload/download-template`,
  PARTY_TEMPLATE_SEARCH_CRITERIA: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/search-criteria`,
  PARTY_TEMPLATE_SEARCH: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/search`,
  PARTY_TEMPLATE_GET_DROPDOWNS: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/company-contact/get`,
  PARTY_TEMPLATE_CREATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/create`,
  PARTY_TEMPLATE_UPDATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/update`,
  PARTY_TEMPLATE_DELETE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/delete`,
  PARTY_TEMPLATE_CREATE_OR_UPDATE: `${process.env.REACT_APP_CLOG_SHARED_SERVICE_ORIGIN_URL}/address-book/party-template/create-template`
}

export default CompanyPreferenceApiUrls
