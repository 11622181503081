const PING_SEARCH_LOG_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/search-log`

const PingSearchLogApiUrls = {
    GET: `${PING_SEARCH_LOG_URL}/get`,

    // For User Detail lookup.
    USER_DETAIL_GET: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user/detail/get`
}

export default PingSearchLogApiUrls
