const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const UserForUserGroupApiUrls = {
  GET: `${USER_RESOURCE_URL}/user/get`,
  GET_USER_DETAIL: `${USER_RESOURCE_URL}/user/detail/get`,
  GETBYID: `${COMMON_URL}/user-for-user-group/hdr/getById`,
  POST: `${COMMON_URL}/user-for-user-group/hdr/create`,
  PUT: `${COMMON_URL}/user-for-user-group/hdr/update`,
  DELETE: `${COMMON_URL}/user-for-user-group/hdr/delete`,
  EXPORT: `${COMMON_URL}/user-for-user-group/hdr/download`
}

export default UserForUserGroupApiUrls
