const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`
const USER_RESOURCE_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr`

const UserForShipperRequestApiUrls = {
  GET: `${COMMON_URL}/user-for-shipper-request/hdr/get`,
  GET_USER_DETAIL: `${USER_RESOURCE_URL}/user/detail/get`,
  EXPORT: `${COMMON_URL}/user-for-shipper-request/hdr/download`
}

export default UserForShipperRequestApiUrls
