const PING_EVENT_CODE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/event-code`

const PingEventCodeApiUrls = {
    GET: `${PING_EVENT_CODE_URL}/get`,
    POST: `${PING_EVENT_CODE_URL}/create`,
    PUT: `${PING_EVENT_CODE_URL}/update`,
    DELETE: `${PING_EVENT_CODE_URL}/delete`
}

export default PingEventCodeApiUrls
