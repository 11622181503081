const SCO_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}`
const SCO_ORDER_FORM_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/job/order-form`

const SCOJobApiurls = {
  GET: `${SCO_URL}/jobconfig/get`,
  POST: `${SCO_URL}/jobconfig/create`,
  PUT: `${SCO_URL}/jobconfig/update`,
  DELETE: `${SCO_URL}/jobconfig/delete`,
  CREATE: `${SCO_URL}/jobconfig/submit/create`,
  GETWORKFLOW: `${SCO_URL}/jobconfig/hdr/get`,
  // GETCONFIG: `${SCO_URL}/jobconfig/hdr/getConfig`,
  UPDATE: `${SCO_URL}/jobconfig/submit/update`,
  GETJOBATTRIBUTE: `${SCO_URL}/jobconfig/getJobAttributes`,

  GETMYJOB: `${SCO_URL}/job/get`,
  CREATEJOB: `${SCO_URL}/job/create-new-job`,
  DELETEJOB: `${SCO_URL}/job/delete`,
  GETTASKSTATUS: `${SCO_URL}/job/hdr/getTaskStatus`,
  GETJOBCONFIG: `${SCO_URL}/job/hdr/getJobConfig`,
  GETTIMEZONE: `${SCO_URL}/job/hdr/getTimezone`,
  HDR_CREATE_JOB: `${SCO_URL}/job/hdr/newjob`,
  HDR_UPDATE_JOB: `${SCO_URL}/job/hdr/updatejob`,
  HDR_CANCEL_JOB: `${SCO_URL}/job/hdr/cancelJob`,
  GET_JOBTASK: `${SCO_URL}/job/hdr/getJobTask`,
  DOCUMENT_UPLOAD: `${SCO_URL}/job/documents/hdr/create`,
  DOCUMENT_GETBYJOBID: `${SCO_URL}/job/documents/getByJobId`,
  DOCUMENT_DOWNLOAD: `${SCO_URL}/job/documents/download/get`,
  GET_TASK_HISTORY: `${SCO_URL}/job/taskhistory/hdr/get`,
  DOCUMENT_GETDOCTYPE: `${SCO_URL}/job/documents/hdr/docType`,
  GET_JOB_TASK_TYPE: `${SCO_URL}/job/hdr/getTaskType`,
  GET_MOVEMENT_TYPE: `${SCO_URL}/job/hdr/getMovementType`,
  PROCESS_INVENTORY: `${SCO_URL}/company/inventory/processInvFile`,
  PROCESS_INVENTORY_FORM: `${SCO_URL}/company/inventory/processInvFile`,

  GET_LOCODE: `${SCO_URL}/job/hdr/getLocode`,
  GETJOBTYPEATTRIBUTES: `${SCO_URL}/job/hdr/getJobTypeAttribute`,
  GETJOBATTRIBUTES: `${SCO_URL}/job/hdr/getJobAttributes`,

  GET_RATECARD_BY_CUST: `${SCO_URL}/company/contract/hdr/getRateCardByCustomer`,
  GET_RATECARD_BY_ISSU: `${SCO_URL}/company/contract/hdr/getRateCardByIssuer`,

  GET_DOC_CONVERSION: `${SCO_URL}/docconversion/get`,
  ADD_DOC_CONVERSION: `${SCO_URL}/docconversion/createDocConversion`,
  DRAFT_DOC_CONVERSION: `${SCO_URL}/docconversion/createDraftDocConversion`,
  UPDATE_DOC_CONVERSION: `${SCO_URL}/docconversion/updateDocConversion`,
  UPDATE_SAVE_DRAFT_DOC_CONVERSION: `${SCO_URL}/docconversion/updateSaveDraftDocConversion`,
  DELETE_DOC_CONVERSION: `${SCO_URL}/docconversion/deleteDocConversion`,
  VIEW_DOC_CONVERSION: `${SCO_URL}/docconversion/viewDocConversion`,
  GET_INPUT_DOCUMENT: `${SCO_URL}/docconversion/getInputDocuments`,
  GET_OUTPUT_DOCUMENT: `${SCO_URL}/docconversion/getOutputDocuments`,
  DOWNLOAD_INPUT_DOC_CONVERSION_DOCUMENTS: `${SCO_URL}/docconversion/downloadInputDocument`,
  DOWNLOAD_OUTPUT_DOC_CONVERSION_DOCUMENTS: `${SCO_URL}/docconversion/downloadOutputDocument`,
  GET_DOC_CONVERSION_BY_ID: `${SCO_URL}/docconversion/getById`,


  /** BE executeSearch have custom logic. Check if needed for DDL */
  GET_JOB_REQUEST: `${SCO_URL}/jobrequest/get`,
  GET_REQUEST_FOR_JOB: `${SCO_URL}/jobrequest/getRequest`,
  REJECT_JOB_REQUEST: `${SCO_URL}/jobrequest/rejectRequest`,
  CANCEL_JOB_REQUEST: `${SCO_URL}/jobrequest/cancelRequest`,
  GET_REQUESTOR_BY_RECEIVER_PARTY: `${SCO_URL}/jobrequest/getRequestorParties`,


  /** Start for InvDetails URL */
  ORDER_FORM_GET_BY_JOB_ID: `${SCO_ORDER_FORM_URL}/getByJobId`,
  ADD_ORDER_FORM: `${SCO_ORDER_FORM_URL}/addOrderForm`,
  UPDATE_ORDER_FORM: `${SCO_ORDER_FORM_URL}/updateOrderForm`

}




export default SCOJobApiurls