const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`

const CommonCityMappingApiUrls = {
      GET: `${CEC_URL}/commoncity-mapping/get`,
      CREATE: `${CEC_URL}/commoncity-mapping/createCommonLocationMapping`,
      UPDATE: `${CEC_URL}/commoncity-mapping/updateCommonLocationMapping`,
      DELETE: `${CEC_URL}/commoncity-mapping/deleteCommonLocationMapping`,
      GETBYID: `${CEC_URL}/commoncity-mapping/getById`
}

export default CommonCityMappingApiUrls