const CEC_URL = `${process.env.REACT_APP_SCO_ORIGIN_URL}/cec`
const SHIPMENT_PROVIDER_CONNECTOR_MAPPING = `${CEC_URL}/shipment-providers/connector-mappings`
const GET = `get`
const CREATE = `create`
const UPDATE = `update`
const DELETE = `delete`

const ShipmentProviderConnectorMappingUrls = {
  SHIPMENT_PROVIDER_CONNECTOR_MAPPING: SHIPMENT_PROVIDER_CONNECTOR_MAPPING,
  GET: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${GET}`,
  CREATE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${CREATE}`,
  UPDATE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${UPDATE}`,
  DELETE: `${SHIPMENT_PROVIDER_CONNECTOR_MAPPING}/${DELETE}`
}
export default ShipmentProviderConnectorMappingUrls