const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const UserSubscribedUserGroupApiUrls = {
  GET: `${COMMON_URL}/user-for-user-group/user-subscribed-user-group/get`,
  POST: `${COMMON_URL}/user-for-user-group/user-subscribed-user-group/create`,
  PUT: `${COMMON_URL}/user-for-user-group/user-subscribed-user-group/update`,
  DELETE: `${COMMON_URL}/user-for-user-group/user-subscribed-user-group/delete`,
  EXPORT: `${COMMON_URL}/user-for-user-group/user-subscribed-user-group/download`
}

export default UserSubscribedUserGroupApiUrls
