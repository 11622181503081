const PING_PARTY_CONFIG_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/party-config`
const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`

const PingPartyConfigApiUrls = {
    GET_FROM_TPR_PARTY: `${TPR_PARTY_URL}/get`,
    GET: `${PING_PARTY_CONFIG_URL}/get`,
    PUT: `${PING_PARTY_CONFIG_URL}/update`
}

export default PingPartyConfigApiUrls
