const PING_SEARCH_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/v2.0`
const PING_SEARCH_BOOKING_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/booking`
const PING_SEARCH_FAVOURITE_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/favourite`
const PING_SEARCH_HISTORY_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping/search-history`

const PingSearchApiUrls = {

    // booking list
    GET_BOOKING_LIST: `${PING_SEARCH_BOOKING_URL}/get`,
    GET_BOOKING_COUNT: `${PING_SEARCH_BOOKING_URL}/count`,

    // search history
    GET_SEARCH_HISTORY: `${PING_SEARCH_HISTORY_URL}/get`,
    ADD_SEARCH_HISTORY: `${PING_SEARCH_HISTORY_URL}/create`,

    // search favourite
    GET_SEARCH_FAVOURITE: `${PING_SEARCH_FAVOURITE_URL}/get`,
    ADD_SEARCH_FAVOURITE: `${PING_SEARCH_FAVOURITE_URL}/create`,
    DELETE_SEARCH_FAVOURITE: `${PING_SEARCH_FAVOURITE_URL}/delete`,
    EXISTS_SEARCH_FAVOURITE: `${PING_SEARCH_FAVOURITE_URL}/exists`,

    // booking ref search
    BOOKING_INFO_SEARCH: `${PING_SEARCH_URL}/info-search/booking-ref`,
    BOOKING_MILESTONE_SEARCH: `${PING_SEARCH_URL}/milestone-search/booking-ref`,

    // container search
    CONTAINER_INFO_SEARCH: `${PING_SEARCH_URL}/info-search/container`,
    CONTAINER_MILESTONE_SEARCH: `${PING_SEARCH_URL}/milestone-search/container`,

    // bl num search
    BL_NUM_INFO_SEARCH: `${PING_SEARCH_URL}/info-search/bl-num`,
    BL_NUM_MILESTONE_SEARCH: `${PING_SEARCH_URL}/milestone-search/bl-num`,

    // vessel search
    VESSEL_NAME_LIST: `${PING_SEARCH_URL}/vessel-name/get`,
    VESSEL_INFO_SEARCH: `${PING_SEARCH_URL}/info-search/vessel`,
    VESSEL_MILESTONE_SEARCH: `${PING_SEARCH_URL}/milestone-search/vessel`
}

export default PingSearchApiUrls
