const COMMON_URL = `${process.env.REACT_APP_EDI_ORIGIN_URL}/transaction-log`

const TransactionLogApiUrls = {
  GET: `${COMMON_URL}/get`,
  POST: `${COMMON_URL}/upload/create`,
  PUT: `${COMMON_URL}/update`,
  DELETE: `${COMMON_URL}/delete`,
  EXPORT: `${COMMON_URL}/download/get`,
  EXPORT_DOWNLOAD: `${COMMON_URL}/download`
};

export default TransactionLogApiUrls
