const PING_EVENT_HUB_LOG_URL = `${process.env.REACT_APP_PING_ORIGIN_URL}/ping-admin/event-hub`

const PingEventHubLogApiUrls = {
    SUBSCRIPTION_GET: `${PING_EVENT_HUB_LOG_URL}/subscription/get`,
    PULL_API_LOG_GET: `${PING_EVENT_HUB_LOG_URL}/pull-api-log/get`,
    SUBSCRIBE_API_LOG_GET: `${PING_EVENT_HUB_LOG_URL}/subscribe-api-log/get`,
    BATCH_LOG_GET: `${PING_EVENT_HUB_LOG_URL}/batch-log/get`,
    PUSH_API_LOG_GET: `${PING_EVENT_HUB_LOG_URL}/push-api-log/get`,

    // For CustomLookup for TPR Party
    TPR_PARTY_GET: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`
}

export default PingEventHubLogApiUrls
