const CALISTA_PARTY_COMPANY_INFO_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/calista-admin/company-info`
const CALISTA_PARTY_COMPANY_INFO_MICROSITE_URL = `${CALISTA_PARTY_COMPANY_INFO_URL}/microsite`
const CALISTA_PARTY_COMPANY_INFO_MICROSITE_PREVIEW_URL = `${CALISTA_PARTY_COMPANY_INFO_MICROSITE_URL}/preview`

const CalistaPartyCompanyInfoApiUrls = {
    GET_COMPANY_DETAILS: `${CALISTA_PARTY_COMPANY_INFO_URL}/getByParty`,
	CREATE_COMPANY_DETAILS: `${CALISTA_PARTY_COMPANY_INFO_URL}/createByParty`,
	GET_MICROSITE: `${CALISTA_PARTY_COMPANY_INFO_MICROSITE_URL}/get`,
	CREATE_MICROSITE: `${CALISTA_PARTY_COMPANY_INFO_MICROSITE_URL}/create`,
	GET_MICROSITE_PREVIEW: `${CALISTA_PARTY_COMPANY_INFO_MICROSITE_PREVIEW_URL}/get`,
	UPDATE_MICROSITE_PREVIEW: `${CALISTA_PARTY_COMPANY_INFO_MICROSITE_PREVIEW_URL}/actions-update`
}

export default CalistaPartyCompanyInfoApiUrls
