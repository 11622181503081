const COMMON_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/account-setup`

const AccessLevelRulePermissionApiUrls = {
  GET_BY_ID: `${COMMON_URL}/access-level-rule/access-level-rule-permission/getById`,
  GET: `${COMMON_URL}/access-level-rule/access-level-rule-permission/get`,
  POST: `${COMMON_URL}/access-level-rule/access-level-rule-permission/create`,
  PUT: `${COMMON_URL}/access-level-rule/access-level-rule-permission/update`,
  DELETE: `${COMMON_URL}/access-level-rule/access-level-rule-permission/delete`,
  EXPORT: `${COMMON_URL}/access-level-rule/access-level-rule-permission/download`,
  GET_MODULES_FOR_USER: `${COMMON_URL}/access-level-rule/access-level-rule-permission/get-modules-for-user`,
  GET_MENUS_FOR_USER: `${COMMON_URL}/access-level-rule/access-level-rule-permission/get-menus-for-user`
}

export default AccessLevelRulePermissionApiUrls
