const TMP_URL = `${process.env.REACT_APP_TMP_ORIGIN_URL}`

const TradeMgmtApiUrls = {
  GET_HSMaster: `${TMP_URL}/hsmaster/get`,
  GET_MYPRODUCT: `${TMP_URL}/myproduct/get`,
  GET_TMP_COUNTRY: `${TMP_URL}/common/country/get`,
  FTA_CALCULATE: `${TMP_URL}/ftacalculator/calculate`,
  GET_RULES_OF_ORIGIN: `${TMP_URL}/fta-rules/getByActivityId`,
  SET_ORIGIN_SELECTION: `${TMP_URL}/fta-rules/set-origin-selection`,
  SET_SP_EXPIRY: `${TMP_URL}/fta-rules/set-sp-expiry`,
  GET_DUTY_SAVINGS: `${TMP_URL}/fta-duty-savings/getByActivityId`,
  GET_RELATED_BOM: `${TMP_URL}/fta-related-bom/getByActivityId`,
  SET_ROO_QUALIFICATION_DATE: `${TMP_URL}/fta-origin-calculator/setQualificationDate`,
  GET_ACTIVITY_HISTORY: `${TMP_URL}/ftacalculator/getActivityHistory`,
  GET_ACTIVITY_BY_HISTORY_ID: `${TMP_URL}/ftacalculator/getActivityByHistoryId`,
  GET_PRODUCTS_LIST: `${TMP_URL}/myproduct/getlist`,
  GET_PRODUCT: `${TMP_URL}/myproduct/getById`,
  CREATE_PRODUCT: `${TMP_URL}/myproduct/createProduct`,
  UPDATE_PRODUCT: `${TMP_URL}/myproduct/updateProduct`,
  DELETE_PRODUCT: `${TMP_URL}/myproduct/deleteById`,
  GET_COMPANY_INFO: `${TMP_URL}/admin/company/get`,
  GET_HS_LIST: `${TMP_URL}/hsmaster/get`,
  GET_COMPONENT: `${TMP_URL}/admin/component/get`,
  GET_COUNTRY: `${TMP_URL}/common/country/get`,
  GET_CURRENCY: `${TMP_URL}/common/currency/get`,
  GET_CODEMASTER: `${TMP_URL}/common/config/get`,
  DELETE_ACTIVITY_HISTORY: `${TMP_URL}/ftacalculator/deleteActivityHistory`,
  DOCUMENT_UPLOAD: `${TMP_URL}/myproductdoc/upload`,
  DOCUMENT_GETBYPRODUCTID: `${TMP_URL}/myproductdoc/getByProductId`,
  DOCUMENT_DOWNLOAD: `${TMP_URL}/myproductdoc/download/get`,
  DOCUMENT_DELETE: `${TMP_URL}/myproductdoc/deleteDoc`,
  MASS_UPLOAD: `${TMP_URL}/massUpload/processUpload`,
  GET_DPS_SEARCH: `${TMP_URL}/riskAssessment/v1/riskSearch`,
  GET_DPS_HISTORY: `${TMP_URL}/riskAssessment/v1/riskHistory`,
  GET_DPS_USER_SEARCH_HISTORY: `${TMP_URL}/riskAssessment/v1/history`,
  GET_DPS_PDF_DOWNLOAD: `${TMP_URL}/riskAssessment/v1/downloadProfile`,
  SET_DEMINIMIS_EXPIRY: `${TMP_URL}/fta-rules/set-deminimis-expiry`,
  SET_DEMINIMIS_SELECTION: `${TMP_URL}/fta-rules/set-deminimis-selection`,
  MASTER_UPLOAD: `${TMP_URL}/masterUpload/processUpload`
}

export default TradeMgmtApiUrls
