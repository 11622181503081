const CALISTA_PARTY_CONFIG_URL = `${process.env.REACT_APP_COMMON_SERVICE_ORIGIN_URL}/calista-admin/calista-party-config`
const TPR_PARTY_URL = `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party`
		
const CalistaPartyConfigApiUrls = {
	GET_BY_PARTY_SERVICE: `${TPR_PARTY_URL}/get`,
	GET: `${CALISTA_PARTY_CONFIG_URL}/get`,
	PUT: `${CALISTA_PARTY_CONFIG_URL}/update`,
	GET_DROPDOWN_OPTIONS_SYS_INTEGRATION_USER_ID: `${CALISTA_PARTY_CONFIG_URL}/dropdown-options/sys-integration-user-id/get`
}

export default CalistaPartyConfigApiUrls
