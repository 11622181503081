import { default as React, useState } from 'react'
import { Redirect } from 'react-router-dom'
import 'src/assets/scss/App.scss'
import 'src/assets/scss/_cngModuleBase.scss'
import 'src/assets/scss/_cngUiUxBase.scss'
import 'src/assets/scss/_ftaMain.scss'
import { App as CngApp, layouts, routes, views, ApplicationConfigurations } from 'cng-web-lib'
import { Context as LandedCostContext } from 'src/store/intelligent-advisory/lcc'
import CngRoute from 'src/CngRoute'
import HomePageLayout from 'src/layouts/HomePageLayout'
import HomePageRoute from 'src/routes/HomePageRoute'
import IntroPageRoute from './routes/IntroPage/IntroPageRoute'
import IntroPageCNRoute from './routes/IntroPage/IntroPageCNRoute'
import ThemeLibrary from 'src/theme/ThemeLibrary'
import MicrositeRoute from './routes/Microsite/MicrositeView'

const { CngPublicRoute, CngLoginRoute, CngSetPasswordRoute } = routes
const { CngLayout, CngTopBar, CngSettings } = layouts
const { CngLoginView, CngSetPasswordView } = views
export const LayoutContext = React.createContext()

function App() {
  const cngRoute = new CngRoute()
  const homePageRoute = new HomePageRoute()
  const introPageRoute = new IntroPageRoute()
  const introPageCNRoute = new IntroPageCNRoute()
  const micrositeRoute = new MicrositeRoute()
  const cngPublicRoute = new CngPublicRoute()
  const loginPageRoute = new CngLoginRoute()
  const [displayLayoutWithNoSideBar, setDisplayLayoutWithNoSidebar] =
    useState(false)

    ApplicationConfigurations.update('cngCrudTable', {
  
      filterTriggerByKey: {
  
        shouldEnable: true,
  
        keyName: 'Enter'
  
      }
  
    })

  loginPageRoute.setComponent(() => (
    <CngLoginView
      renderLogo={() => (
        <img
          alt=''
          src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
          style={{ width: 120, height: 30 }}
        ></img>
      )}
    />
  ))

  cngPublicRoute.setLoginRoute(loginPageRoute)
  cngPublicRoute.addConfigurations([
    {
      exact: true,
      path: '/',
      component: () => <Redirect to='/home' />
    },
    // {
    //   path: '/home',
    //   layout: HomePageLayout,
    //   routes: homePageRoute.getConfigurations()
    // },
    {
      path: '/member',
      routes: micrositeRoute.getConfigurations()
    },
    {
      path: '/home',
      routes: introPageRoute.getConfigurations()
    },
    {
      path: '/home-cn',
      routes: introPageCNRoute.getConfigurations()
    }
  ])

  const themeLibrary = new ThemeLibrary()

  return (
    <LandedCostContext>
      {/* this is custom for developers to set layout without sidebar */}
      <LayoutContext.Provider
        value={{
          setDisplayLayoutWithNoSidebar,
          displayLayoutWithNoSideBar
        }}
      >
        <CngApp
          extraCngRouteConfigurations={cngRoute.getConfigurations()}
          publicRoute={cngPublicRoute}
          themeLibrary={new ThemeLibrary()}
          renderCngLayout={(children) => (
            <>
              {!displayLayoutWithNoSideBar && (
                <CngLayout
                  renderTopBar={(openMobileNav, onToggleDesktopSideBar) => (
                    <CngTopBar
                      onMobileNavOpen={openMobileNav}
                      onToggleDesktopSideBar={onToggleDesktopSideBar}
                      renderSettings={() => (
                        <CngSettings
                          themes={themeLibrary.getNames()}
                          languages={[
                            {
                              key: 'english',
                              value: 'en',
                              label: 'English'
                            }
                          ]}
                        />
                      )}
                      renderLogo={() => (
                        <img
                          alt=''
                          src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
                          style={{ width: 120, height: 30 }}
                        ></img>
                      )}
                    />
                  )}
                >
                  {children}
                </CngLayout>
              )}
              {displayLayoutWithNoSideBar && <>{children}</>}
            </>
          )}
        />
      </LayoutContext.Provider>
    </LandedCostContext>
  )
}

export default App
